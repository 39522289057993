/* eslint-disable max-len */
import React, {useEffect, useState} from 'react';
import {
  applicationSelector,
  updateCurrentKonvaObject
} from '../../../slices/application';
import {useDispatch, useSelector} from 'react-redux';

const RotateControl = () => {
  const dispatch = useDispatch();
  const {currentKonvaObject} = useSelector(applicationSelector);
  const [rotateInt, setRotateInt] = useState(0);

  useEffect(() => {
    setRotateInt(currentKonvaObject?.rotation);
  }, [currentKonvaObject]);

  const setRotation = (e) => {
    let input = Number(e);
    if (Number.isNaN(input)) {
      input = 0;
    }
    setRotateInt(input);
    dispatch(updateCurrentKonvaObject({rotation: input}));
  };

  // increments of 15 degrees
  const rotate = (delta) => {
    let rotated = currentKonvaObject?.rotation;
    rotated += parseInt(delta);
    if (rotated < 0) {
      rotated += 360;
    }
    if (rotated > 360) {
      rotated -= 360;
    }
    setRotation(rotated);
  };

  return (
    <div className="hs-form-control-group">
      <label htmlFor="PrintRotate" className="form-label hs-form-label">
        Rotate
      </label>
      <div className="hs-form-control-rotate">
        <button
          type="button"
          aria-label="rotate counter clockwise"
          className="btn uix-btn-outline-default btn-sm input-radius"
          onClick={() => rotate(-15)}>
          <svg
            className="hs-svg-rotate-left"
            enableBackground="new 0 0 512 512"
            version="1.1"
            viewBox="0 0 512 512"
            xmlSpace="preserve"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <path
              className="hs-svg-inherit"
              d="M255.1,5.8C191,5.9,129.3,30.7,83,75.1L46.9,39c-9.5-9.4-24.8-9.4-34.2,0 c-4.5,4.5-7.1,10.7-7.1,17.1v135.1c0,13.4,10.8,24.2,24.2,24.2h135.1c13.4,0,24.2-10.9,24.2-24.2c0-6.4-2.6-12.5-7.1-17.1 l-41.9-41.9c68.3-63.9,175.5-60.2,239.4,8.1s60.2,175.5-8.1,239.4c-63.5,59.4-161.7,61-227.1,3.7c-4.8-4.2-12-3.9-16.5,0.5l-40,40 c-4.7,4.7-4.7,12.4,0,17.2c0.1,0.1,0.3,0.3,0.5,0.4c102.6,92.4,260.7,84.2,353.1-18.4s84.2-260.7-18.4-353.1 C376.9,28.6,317.1,5.7,255.1,5.8z"
            />
          </svg>
        </button>
        <input
          type="number"
          className="form-control hs-form-control"
          style={{width: '4em', paddingRight: '0em'}}
          id="PrintRotate"
          name="PrintRotate"
          min="0"
          max="720"
          step="1"
          value={rotateInt}
          onChange={(e) => setRotation(e.target.value)}
        />
        <button
          type="button"
          aria-label="rotate clockwise"
          className="btn uix-btn-outline-default btn-sm input-radius"
          onClick={() => rotate(15)}>
          <svg
            className="hs-svg-rotate-right"
            enableBackground="new 0 0 512 512"
            version="1.1"
            viewBox="0 0 512 512"
            xmlSpace="preserve"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <path
              className="hs-svg-inherit"
              d="M256.9,5.8c64.2,0,125.8,24.8,172.2,69.2l36.1-36c9.5-9.4,24.8-9.4,34.2,0 c4.5,4.5,7.1,10.7,7.1,17.1v135.1c0,13.4-10.8,24.2-24.2,24.2H347.1c-13.4,0-24.2-10.9-24.2-24.2c0-6.4,2.6-12.5,7.1-17.1l41.9-41.9 c-68.3-63.9-175.5-60.2-239.4,8.1s-60.2,175.5,8.1,239.4c63.5,59.4,161.7,61,227.1,3.7c4.8-4.2,12-3.9,16.5,0.5l40,40 c4.7,4.7,4.7,12.4,0,17.2c-0.1,0.1-0.3,0.3-0.5,0.4C321.2,534,163.1,525.7,70.7,423.2S-13.5,162.5,89.1,70.1 C135.1,28.6,194.9,5.7,256.9,5.8z"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default RotateControl;
