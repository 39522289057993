import {library} from '@fortawesome/fontawesome-svg-core';
import {fab} from '@fortawesome/free-brands-svg-icons';
import {far} from '@fortawesome/free-regular-svg-icons';
import {fas} from '@fortawesome/free-solid-svg-icons';
import React, {Suspense, useState, useLayoutEffect} from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import './App.scss';
import Printing from './components/Pages/Printing/Printing.jsx';
import OrderConfirm from './components/Pages/OrderConfirm/OrderConfirm.jsx';

library.add(fab, far, fas);

const Loader = () => <div>loading...</div>;

export default function App() {
  // When user is not present, don't render the app
  const [displayApp, setdisplayApp] = useState(false);

  useLayoutEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    if ((queryParams.get('uuid')?.length ?? -1) > 0) setdisplayApp(true);
  }, []);

  return (
    <>
      <link
        href="https://fonts.googleapis.com/css?family=Roboto Slab"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/css?family=Montserrat"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/css?family=Bebas Neue"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/css?family=Comfortaa"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/css?family=Alfa Slab One"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/css?family=Pacifico"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/css?family=Roboto Mono"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/css?family=Courier Prime"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/css?family=Yellowtail"
        rel="stylesheet"
      />
      <Router>
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route exact path="/health_check" element={<h3>healthy</h3>} />
            {displayApp ? (
              <Route exact path="/confirm" element={<OrderConfirm />} />
            ) : null}
            {displayApp ? <Route path="/*" element={<Printing />} /> : null}
          </Routes>
        </Suspense>
      </Router>
    </>
  );
}
