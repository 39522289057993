import React, {useState, useEffect} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import styled from 'styled-components';
import {useDispatch} from 'react-redux';
import {updateShowDisclaimer} from '../../../slices/application';
import appConfig from '../../../config';
import DisclaimerModal from '../../Modal/DisclaimerModal';
import '../../Buttons/Buttons.scss';
import {useSelector} from 'react-redux';
import {applicationSelector} from '../../../slices/application';

import siteHTML from '../../SiteSpecific/SiteSpecific';

const OrderConfirm = () => {
  const {design} = useSelector(applicationSelector);
  const site = design.siteCode;
  const navigate = useNavigate();
  const location = useLocation();
  const [state, setState] = useState(location.state);
  const [isDisabled, setIsDisabled] = useState(true);
  const [submitDisabled, setsubmitDisabled] = useState(false);
  const dispatch = useDispatch();
  const cdnUri = appConfig.cdnUri;

  useEffect(() => {
    setState(location.state);
  }, [location]);
  const Cancel = () => {
    navigate('/choose-quantity');
  };

  const [checkedState, setCheckedState] = useState([false, false]);

  const handleOnChange = (position) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );

    setCheckedState(updatedCheckedState);

    if (updatedCheckedState.every((e) => e)) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  };

  function showDisclaimer() {
    dispatch(updateShowDisclaimer(true));
  }

  function sendCartDataToParent() {
    // disable submit button while sending
    setIsDisabled(true);
    // also disable the checkboxes and cancel
    setsubmitDisabled(true);
    const chosenDesign = {
      designID: state.designID,
      product: state.product,
      comment: state.orderInstructions,
      quantity: state.quantity
    };
    window.parent.postMessage(chosenDesign, '*');
    localStorage.removeItem('lastDesign');
  }

  return (
    <Confirm>
      <main className="hs-page hs-lab printing">
        <div className="container hs-page-container center-text">
          <h1 className={`confirm-header h-style ${site}`}>CONFIRM ORDER</h1>
          <section className={`hsp-preview ${site}`}>
            <img
              src={cdnUri + '/' + state.cartThumbnail + '?' + state.time}
              alt="preview"
            />
            <p className={`desctxt ${site}`}>{state.description}</p>
          </section>
          <div className="boxspace">
            <h2 className={`ordertxt ${site}`}>Total Price: {state.price}</h2>
            <h3 className={`ordertxt ${site}`}>
              Quantity: {state.quantity} / {state.perPiece} ea
            </h3>
          </div>

          <div className="checkboxes">
            <div className="input-group">
              <input
                type="checkbox"
                id="disclaimer1"
                name="disclaimer"
                value="disclaimer1"
                checked={checkedState[0]}
                disabled={submitDisabled}
                onChange={() => handleOnChange(0)}
              />
              <label htmlFor="disclaimer1">
                I understand {siteHTML('name')}&apos;s{' '}
                <span className="hypertext" onClick={showDisclaimer}>
                  Heatshrink Printing Disclaimer
                </span>
              </label>
            </div>
            <div className="input-group">
              <input
                type="checkbox"
                id="disclaimer2"
                name="disclaimer"
                value="disclaimer2"
                checked={checkedState[1]}
                disabled={submitDisabled}
                onChange={() => handleOnChange(1)}
              />
              <label htmlFor="disclaimer2">
                I understand that the visual print illustrated above is the
                digital proof for my order, i.e. “What you see is what you get”,
                that exact colors may vary, that any print not completely
                visible on one side of the tubing will not wrap to the other
                side, and that only one side of the tubing will be printed on. I
                also understand that no changes can be made to the heatshrink
                tubing and print once the design has been submitted and approved
                by the processing team and moved to the production queue.
              </label>
            </div>
          </div>

          <div className="boxspace">
            <button
              id="submitBtn"
              type="button"
              className="btn btn-success btn-sm input-radius"
              disabled={isDisabled}
              onClick={sendCartDataToParent}>
              Add Heatshrink to Cart
            </button>
            <button
              type="button"
              className={`btn btn-outline-primary btn-sm input-radius ${site}`}
              id="cancel-confirm"
              disabled={submitDisabled}
              onClick={Cancel}>
              Cancel
            </button>
          </div>
        </div>
        <DisclaimerModal />
      </main>
    </Confirm>
  );
};

export default OrderConfirm;

const Confirm = styled.div`
  button.btn {
    width: 240px;
    margin: 10px auto;
    display: block;
  }

  .boxspace {
    margin: 25px 0 40px;
  }

  .center-text {
    text-align: center;
  }

  .checkboxes {
    margin-bottom: 25px;
  }

  .confirm-header {
    margin: 20px auto 30px;
    padding: 0;
  }

  .desctext {
    color: #0a3357;
    font-size: 1rem;
    text-transform: uppercase;
    font-family:
      'Montserrat',
      -apple-system,
      BlinkMacSystemFont,
      'Segoe UI',
      Roboto,
      'Helvetica Neue',
      Arial,
      'Noto Sans',
      sans-serif,
      'Apple Color Emoji',
      'Segoe UI Emoji',
      'Segoe UI Symbol',
      'Noto Color Emoji';
    margin: auto 20px;
    &.TF {
      color: black;
    }
  }

  .h-style {
    color: #0a3257;
    font-family:
      'Montserrat',
      -apple-system,
      BlinkMacSystemFont,
      'Segoe UI',
      Roboto,
      'Helvetica Neue',
      Arial,
      'Noto Sans',
      sans-serif,
      'Apple Color Emoji',
      'Segoe UI Emoji',
      'Segoe UI Symbol',
      'Noto Color Emoji';
    font-weight: 700;
    &.TF {
      color: black;
    }
  }

  .hsp-preview {
    background-color: #f8f8f8;
    border: 2px solid #8bb8d1;
    display: block;
    margin: 0 auto;
    position: relative;
    width: 100%;
    max-width: 800px;
    min-width: fit-content;
    overflow-y: clip;
    padding-bottom: 10px;
    img {
      border-bottom: #f8f8f8 1px solid;
      max-height: 330px;
      height: auto;
      max-width: 100%;

      padding: 30px;
    }
    &.TF {
      border-color: black;
    }
  }

  .hypertext {
    cursor: pointer;
    color: #337ab7;
  }

  .input-group {
    margin: 10px 0;
    input {
      margin: 5px 10px 0 0;
      align-self: self-start;
    }

    label {
      text-align: left;
      display: inline-block;
      width: 90%;
    }
  }

  .ordertxt {
    color: #0a3257;
    font-weight: 400;
    &.TF {
      color: black;
    }
  }

  .btn-outline-primary {
    &.TF {
      border-color: #d9d9d9;
      color: black;
      background-color: #d9d9d9;
      &:hover {
        border-color: #696969;
        background-color: #696969 !important;
        color: white;
      }
      :active,
      :focus {
        border-color: #3e3e3e;
        background-color: #3e3e3e !important;
        color: white;
      }
    }
  }
`;
