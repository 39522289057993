import React from 'react';
import Reorder from '../Dropdowns/Reorder/Reorder';
import './Header.scss';

const LabHeader = () => {
  return (
    <header className="hs-header">
      <h1>Design Your Heat Shrink</h1>
      <div className="hs-controls">
        <Reorder />
      </div>
    </header>
  );
};

export default LabHeader;
