/* eslint-disable max-len */
import React from 'react';
import {
  applicationSelector,
  updateCurrentKonvaObject
} from '../../../slices/application';
import {useDispatch, useSelector} from 'react-redux';
// also "toggle" the opposite to be enabled
// issue when changing actual "disabled", while did change the disabled attr here, button was still disabled!
// so added a slight background color important for "active" invert status
// TODO: set the button to the "selected item" invert status
const InvertControl = ({disabled}) => {
  const dispatch = useDispatch();
  const {currentKonvaObject} = useSelector(applicationSelector);
  const invertImg = (param) => {
    dispatch(updateCurrentKonvaObject({invert: param}));
  };

  return (
    <div className="hs-form-control-group">
      <label
        htmlFor="PrintInvert"
        className="form-label hs-form-label"
        style={disabled ? {color: '#bbbbbb'} : null}>
        Invert
      </label>
      <div
        className="btn-group hs-button-group"
        role="group"
        aria-label="print Invert">
        <button
          type="button"
          aria-label="print inverted foreground"
          className={`btn uix-btn-outline-default btn-sm input-radius
            ${!disabled && !currentKonvaObject?.invert ? 'active' : ''}`}
          id="PrintInvertForeground"
          onClick={() => invertImg(false)}
          disabled={
            currentKonvaObject?.type == 'text' ||
            (!disabled && !currentKonvaObject?.invert)
          }>
          <svg
            className="hs-svg-invert-foreground"
            enableBackground="new 0 0 512 512"
            version="1.1"
            viewBox="0 0 512 512"
            xmlSpace="preserve"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <path
              className="hs-svg-inherit"
              d="M356,156.3V6.3H6v350h150v0h0v150h350v-350H356z M43.5,318.7v-275h275v112.5H156v162.5H43.5z M468.5,468.7h-275 V356.3H356V193.8h112.5V468.7z"
            />
          </svg>
        </button>
        <button
          type="button"
          aria-label="print inverted background"
          className={`btn uix-btn-outline-default btn-sm input-radius
            ${!disabled && currentKonvaObject?.invert ? 'active' : ''}`}
          id="PrintInvertBackground"
          onClick={() => invertImg(true)}
          disabled={
            currentKonvaObject?.type == 'text' ||
            (!disabled && currentKonvaObject?.invert)
          }>
          <svg
            className="hs-svg-invert-background"
            enableBackground="new 0 0 512 512"
            version="1.1"
            viewBox="0 0 512 512"
            xmlSpace="preserve"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <polygon
              className="hs-svg-inherit"
              points="356,156.3 356,356.3 156,356.3 156,156.3 356,156.3 356,6.2 6,6.2 6,356.3 156,356.3 156,506.2 506,506.2 506,156.3 "
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default InvertControl;
