import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {applicationSelector} from '../../slices/application';
import {convertDiameterToText} from '../../utils/utils';
import './SelectTubeLayer.scss';
const CanvasHeader = () => {
  const {appTab, design, heatshrinkCompatibility} =
    useSelector(applicationSelector);
  const {printColor, diameter, length, heatshrinkColor, shrinkRatio} = design;
  const [displayAttributes, setDisplayAttributes] = useState(true);
  const [displayPrintColor, setDisplayPrintColor] = useState(true);
  const isCompatible = heatshrinkCompatibility.heatshrinkSelectionIsCompatible;
  const conversionForText = {
    0: '',
    0.25: '¼',
    0.38: '⅜',
    0.5: '½',
    0.63: '⅝',
    0.75: '¾'
  };
  const lengthText = `${Math.floor(Number(length))}${
    conversionForText[Number(length) - Math.floor(Number(length))]
  }"`;
  const diameterText = convertDiameterToText(Number(diameter));

  useEffect(() => {
    if (appTab === 'choose-quantity' || appTab === 'choose-print') {
      setDisplayAttributes(true);
      setDisplayPrintColor(true);
    } else {
      setDisplayAttributes(
        heatshrinkCompatibility.heatshrinkSelectionIsCompatible
      );
      setDisplayPrintColor(false);
    }
  }, [appTab, heatshrinkCompatibility.heatshrinkSelectionIsCompatible]);

  return (
    <div
      className={`hs-canvas-header ${!isCompatible ? 'invalid-tubing' : ''}`}>
      {displayAttributes ? (
        <label className="hs-canvas-label tubing">{`Standard Heat Shrink Tubing, ${heatshrinkColor}, ${diameterText} Diameter, ${lengthText} Length, ${shrinkRatio} Shrink Ratio`}</label>
      ) : null}

      {displayPrintColor ? (
        <label className="hs-canvas-label print">{`${printColor} Print`}</label>
      ) : null}
    </div>
  );
};

export default CanvasHeader;
