import {createSlice} from '@reduxjs/toolkit';

const queryParams = new URLSearchParams(window.location.search);
export const initialState = {
  user: '',
  uuid: '',
  site: '',
  appTab: 'choose-tubing',
  printTab: 'select-options',
  errorMessage: '',
  infoText: '',
  isInfoModalVisible: false,
  heatshrinkTubeLengths: [
    {value: '1', compatible: true},
    {value: '1.25', compatible: true},
    {value: '1.5', compatible: true},
    {value: '1.75', compatible: true},
    {value: '2', compatible: true},
    {value: '2.25', compatible: true},
    {value: '2.5', compatible: true},
    {value: '2.75', compatible: true},
    {value: '3', compatible: true},
    {value: '3.25', compatible: true},
    {value: '3.5', compatible: true},
    {value: '3.75', compatible: true},
    {value: '4', compatible: true},
    {value: '4.25', compatible: true},
    {value: '4.5', compatible: true},
    {value: '4.75', compatible: true},
    {value: '5', compatible: true},
    {value: '5.25', compatible: true},
    {value: '5.5', compatible: true},
    {value: '5.75', compatible: true},
    {value: '6', compatible: true}
  ],
  heatshrinkCompatibility: {
    heatshrinkSelectionIsCompatible: true,
    diameter: [
      {
        compatible: true,
        value: '0.38'
      },
      {
        compatible: true,
        value: '1.25'
      },
      {
        compatible: true,
        value: '0.25'
      },
      {
        compatible: true,
        value: '0.75'
      },
      {
        compatible: true,
        value: '1'
      },
      {
        compatible: true,
        value: '2'
      },
      {
        compatible: true,
        value: '0.63'
      },
      {
        compatible: true,
        value: '1.5'
      },
      {
        compatible: true,
        value: '0.5'
      }
    ],
    finish: [
      {
        compatible: true,
        value: 'Standard'
      }
    ],
    printColor: [
      {
        compatible: false,
        value: 'Black'
      },
      {
        compatible: false,
        value: 'Green'
      },
      {
        compatible: true,
        value: 'White'
      },
      {
        compatible: false,
        value: 'Blue'
      },
      {
        compatible: false,
        value: 'Red'
      },
      {
        compatible: false,
        value: 'Yellow'
      },
      {
        compatible: true,
        value: 'Gold'
      },
      {
        compatible: true,
        value: 'Silver'
      },
      {
        compatible: false,
        value: 'Purple'
      }
    ],
    heatshrinkColor: [
      {
        compatible: true,
        value: 'Red'
      },
      {
        compatible: true,
        value: 'Brown'
      },
      {
        compatible: true,
        value: 'Gray'
      },
      {
        compatible: true,
        value: 'White'
      },
      {
        compatible: true,
        value: 'Yellow'
      },
      {
        compatible: true,
        value: 'Purple'
      },
      {
        compatible: true,
        value: 'Blue'
      },
      {
        compatible: true,
        value: 'Black'
      },
      {
        compatible: true,
        value: 'Orange'
      },
      {
        compatible: true,
        value: 'Green'
      },
      {
        compatible: true,
        value: 'Clear'
      }
    ],
    shrinkRatio: [
      {
        compatible: true,
        value: '2:1'
      },
      {
        incompatible: ['diameter'],
        compatible: false,
        value: '3:1'
      }
    ]
  },
  printColors: [
    {value: 'Yellow', rgb: '254,219,0', compatible: true},
    {value: 'Silver', rgb: '191,191,191', compatible: true},
    {value: 'Black', rgb: '0,0,0', compatible: false},
    {value: 'Green', rgb: '40,92,77', compatible: true},
    {value: 'Blue', rgb: '16,6,159', compatible: true},
    {value: 'Gold', rgb: '207,175,78', compatible: true},
    {value: 'Red', rgb: '206,0,55', compatible: true},
    {value: 'White', rgb: '255,255,255', compatible: true},
    {value: 'Purple', rgb: '102,56,182', compatible: true}
  ],
  design: {
    designId: '',
    userId: '',
    siteCode: queryParams.get('site') || 'WC',
    lastId: 0, // increase everytime new element created
    artSrc: '',
    canvasHeight: 576,
    canvasWidth: 876,
    heatshrinkColor: 'Black',
    printColor: 'White',
    diameter: '0.38',
    finish: 'Standard',
    shrinkRatio: '2:1',
    length: '2',
    updatedAt: '',
    orders: [],
    orderInstructions: '',
    mostRecentSelectedTubingAttribute: '', // whatever we last selected
    lastSelectedPrimaryTubingAttribute: '', // should be either ratio or finish
    lastSelectedSecondaryTubingAttribute: '', // should be either diameter or color
    objects: []
  },
  currentKonvaObject: null,
  requestedAppTab: null,
  printColor: {},
  showDisclaimer: false,
  isArtUpdated: false,
  singleUploadCheck: false,
  autoSelect: false,
  recentUploads: []
};

function konvaObjectIsSwapped(state, payload) {
  return (
    state.currentKonvaObject?.id != null &&
    payload?.id != null &&
    state.currentKonvaObject?.id != payload?.id
  );
}

function handleSwapKonvaObject(state, payload) {
  if (konvaObjectIsSwapped(state, payload)) {
    updateDesignWithKonvaObject(state);
  }
  state.autoSelect = false;
  state.currentKonvaObject = {...state.currentKonvaObject, ...payload};
  state.appTab = 'choose-print';
}

function updateDesignWithKonvaObject(state) {
  const index = state.design.objects.findIndex(
    (obj) => obj.id === state.currentKonvaObject.id
  );
  if (index == -1) {
    // add object to design if not found
    state.design.objects.push(state.currentKonvaObject);
    state.design.lastId = state.currentKonvaObject.id;
  } else {
    // replace object if it is in design.
    state.design.objects[index] = state.currentKonvaObject;
    state.design.lastId = state.currentKonvaObject.id + 1;
  }
}

function handleNullKonvaPayload(state) {
  if (state.currentKonvaObject) {
    state.printTab = 'select-options';
  }
  state.currentKonvaObject = null;
}

function setPrintTabByType(state) {
  const index = state.design.objects.findIndex(
    (obj) => obj.id == state.currentKonvaObject.id
  );
  if (state.currentKonvaObject.type == 'text') {
    if (index == -1) state.printTab = 'add-text';
    else state.printTab = 'edit-text';
  } else {
    if (index == -1) state.printTab = 'add-art';
    else state.printTab = 'edit-art';
  }
}

function getPrintColor(color) {
  const selectColor = initialState.printColors.find(
    ({value}) => value === color
  );
  return {
    textColor: 'rgb(' + selectColor.rgb + ')',
    imageColor: selectColor.rgb
  };
}

function changeTab(state, tab) {
  state.requestedAppTab = null;
  state.appTab = tab;
  // when click tab to nav to choose art, go to main choose art menu
  if (tab == 'choose-print') {
    state.printTab = 'select-options';
  }
}

function getLastId(state, payload) {
  const objects = payload?.objects ?? state.design?.objects;
  let greatest = state.design?.lastId ?? 0;
  if (objects) {
    objects.forEach((obj) => {
      if (obj && Number(obj.id) > greatest) greatest = Number(obj.id);
    });
  }
  return greatest;
}

// A slice for the application with our reducers
const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    updateUuid: (state, {payload}) => {
      state.uuid = payload;
    },
    updateDesign: (state, {payload}) => {
      state.design = {
        ...state.design,
        ...payload,
        lastId: getLastId(state, payload)
      };
      state.autoSelect = false;
      if (payload.printColor)
        state.printColor = getPrintColor(payload.printColor);
    },
    updateShowDisclaimer: (state, {payload}) => {
      state.showDisclaimer = payload;
    },

    updateInfoModal: (state, {payload}) => {
      state.infoText = payload.infoText;
      state.isInfoModalVisible = payload.isInfoModalVisible;
    },

    fetchDesignSuccess: (state, {payload}) => {
      state.design = payload;
    },
    requestAppTabUpdate: (state, {payload}) => {
      if (state.currentKonvaObject) {
        state.requestedAppTab = payload;
      } else {
        changeTab(state, payload);
      }
    },
    updateRequestedAppTab: (state, {payload}) => {
      state.requestedAppTab = payload;
    },
    updateAppTab: (state, {payload}) => {
      state.currentKonvaObject = null;
      changeTab(state, payload);
    },
    updatePrintTab: (state, {payload}) => {
      state.printTab = payload;
    },
    updateCurrentKonvaObject: (state, {payload}) => {
      if (payload == null) {
        handleNullKonvaPayload(state);
      } else {
        handleSwapKonvaObject(state, payload);
        setPrintTabByType(state);
      }
    },
    updatePrintColor: (state, {payload}) => {
      state.printColor = payload;
    },
    requestHeatshrinkCompatibilityStarted: () => {},
    requestHeatshrinkCompatibilitySuccess: (state, {payload}) => {
      state.heatshrinkCompatibility = {
        ...state.heatshrinkCompatibility,
        ...payload
      };
    },
    requestHeatshrinkCompatibilityFailed: (state) => {
      state.errorMessage = 'error fetching compatible tubing';
      console.log('fetch of compatibility failed');
    },
    toggleIsArtUpdated: (state) => {
      state.isArtUpdated = !state.isArtUpdated;
    },
    doSingleUpload: (state, {payload}) => {
      state.singleUploadCheck = payload;
    },
    doAutoSelect: (state) => {
      state.autoSelect = true;
    },
    updateRecentUploads: (state, {payload}) => {
      state.recentUploads = payload;
    }
  }
});
// Actions generated from the slice
export const {
  updateUuid,
  fetchDesignSuccess,
  updateDesign,
  updateSuppliedDesignId,
  updateRequestedAppTab,
  requestAppTabUpdate,
  updateAppTab,
  updatePrintTab,
  updateInfoModal,
  updateAppLanguageSuccess,
  updateCurrentKonvaObject,
  updatePrintColor,
  updateShowDisclaimer,
  requestHeatshrinkCompatibilityStarted,
  requestHeatshrinkCompatibilitySuccess,
  requestHeatshrinkCompatibilityFailed,
  toggleIsArtUpdated,
  doSingleUpload,
  doAutoSelect,
  updateRecentUploads
} = applicationSlice.actions;
// A selector
export const applicationSelector = (state) => state.application;
// The reducer
export default applicationSlice.reducer;
