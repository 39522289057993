import React, {useRef, useEffect, useState} from 'react';
import {Image, Transformer} from 'react-konva';
import {useDispatch, useSelector} from 'react-redux';
import useImage from 'use-image';
import {
  applicationSelector,
  updateCurrentKonvaObject
} from '../../slices/application';
import './DrawLayer.scss';

const ImageObject = ({imageObject, measurements, handleChange}) => {
  const dispatch = useDispatch();
  const imageRef = useRef();
  const imageTransformerRef = useRef();
  const {canvasHeight, padding, tubeWidth, tubeHeight, inset} = measurements;
  // saving selectColor as string of comma seperated values
  // images and string need to complete different versions

  const {currentKonvaObject, printColor} = useSelector(applicationSelector);

  const [isSelected, setSelected] = useState(
    currentKonvaObject?.id === imageObject.id
  );
  const [imageObjectClone, setImageObjectClone] = useState(
    isSelected ? currentKonvaObject : imageObject
  );

  const [image] = useImage(imageObjectClone.url, 'Anonymous');

  useEffect(() => {
    setImageObjectClone(imageObject);
  }, [imageObject]);

  useEffect(() => {
    if (currentKonvaObject === null) setImageObjectClone(imageObject);
    if (currentKonvaObject?.id === imageObject.id) {
      setImageObjectClone(currentKonvaObject);
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [currentKonvaObject]);

  // when image is loaded we need to cache the shape
  useEffect(() => {
    imageRef.current.cache();
  }, [
    imageObjectClone?.x,
    imageObjectClone?.y,
    imageObjectClone?.url,
    image,
    printColor
  ]);

  useEffect(() => {
    if (isSelected) {
      //move transform box to front so it doesn't hide behind other text/images
      imageTransformerRef.current.moveToTop();
      // we need to attach transformer manually
      imageTransformerRef.current.nodes([imageRef.current]);
      imageTransformerRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  const selectHandler = (e) => {
    if (!isSelected) {
      e.target.moveToTop();
      e.target.getLayer().moveToTop();
      setSelected(true);
      dispatch(updateCurrentKonvaObject(imageObjectClone));
    }
  };

  // custom recolor-er to switch pixels to selected color or transparent
  // color is an array of 3 values
  const imgColorer = function (imagedata) {
    const data = imagedata.data;
    const totalPixels = data.length;
    const width = imagedata.width;
    const height = imagedata.height;
    // sharpen by converting to color or transparent
    const color = printColor.imageColor.split(',');
    const threshold = imageObjectClone.threshold * 255;
    for (let i = 0; i < totalPixels; i += 4) {
      const isLastTwoPixelsInRow =
        (i + 4) % (width * 4) === 0 || (i + 8) % (width * 4) === 0;
      const isTwoBottomRows = i >= (height - 2) * width * 4;

      if (isLastTwoPixelsInRow || isTwoBottomRows) {
        imagedata.data[i + 0] = 255; // make it an invisible red pixel for fun
        imagedata.data[i + 1] = 0;
        imagedata.data[i + 2] = 0;
        imagedata.data[i + 3] = 0;
      } else {
        if (
          (0.34 * data[i] + 0.5 * data[i + 1] + 0.16 * data[i + 2] >=
            threshold) ^
          imageObjectClone.invert
        ) {
          imagedata.data[i + 0] = color[0]; // r
          imagedata.data[i + 1] = color[1]; // g
          imagedata.data[i + 2] = color[2]; // b
          imagedata.data[i + 3] = 255;
        } else {
          imagedata.data[i + 3] = 0;
        }
      }
    }
  };

  const changeWrapper = () => {
    handleChange(imageObjectClone, imageRef.current);
  };

  // import Konva to use its filters, filters have to be applied to cached image.
  return (
    <>
      <Image
        image={image}
        onMouseUp={selectHandler}
        onTap={selectHandler}
        crossOrigin={'Anonymous'}
        ref={imageRef}
        {...imageObjectClone}
        id={String(imageObjectClone.id)}
        selectColor={printColor.imageColor}
        x={imageObjectClone?.x * tubeWidth + inset + padding}
        y={(canvasHeight - tubeHeight) / 2 + imageObjectClone?.y * tubeHeight}
        scaleX={
          (imageObjectClone?.scaleX * tubeWidth) / imageObjectClone?.width
        }
        scaleY={
          (imageObjectClone?.scaleY * tubeHeight) / imageObjectClone?.height
        }
        draggable={isSelected}
        filters={[imgColorer]}
        contrast={30}
        onTransformEnd={changeWrapper}
        onDragEnd={changeWrapper}
      />
      {isSelected && (
        <Transformer
          ref={imageTransformerRef}
          rotateEnabled={false}
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
    </>
  );
};

export default ImageObject;
