import React, {createRef, useEffect, useState} from 'react';
import {Stage} from 'react-konva';
import {useDispatch, useSelector, Provider, useStore} from 'react-redux';
import {
  applicationSelector,
  updateCurrentKonvaObject,
  updateDesign
} from '../../slices/application';
import {extractCanvasMeasurementsFromDesign} from '../../utils/utils';
import './DrawLayer.scss';
import TextLayer from './TextLayer';
import rootReducer from '../../slices';
import ImageLayer from './ImageLayer';
import FontFaceObserver from 'fontfaceobserver';

const DrawLayer = () => {
  const store = useStore(rootReducer);
  const dispatch = useDispatch();
  const {design} = useSelector(applicationSelector, (oldState, newState) => {
    return (
      oldState.design.designId === newState.design.designId &&
      oldState.design.printColor === newState.design.printColor &&
      oldState.design.canvasHeight === newState.design.canvasHeight &&
      oldState.design.canvasWidth === newState.design.canvasWidth &&
      oldState.design.objects == newState.design.objects
    );
  });
  const {currentKonvaObject} = useSelector(applicationSelector);
  const [measurements, setMeasurements] = useState(
    extractCanvasMeasurementsFromDesign(design)
  );
  const stageRef = createRef(null);
  const textLayerRef = createRef(null);
  const imageLayerRef = createRef(null);
  const [fontsLoaded, setFontsLoaded] = useState(false);

  useEffect(() => {
    const updateDesignSize = () => {
      const drawingElement = document.getElementsByClassName('drawing')[0];
      if (drawingElement) {
        const {clientWidth, clientHeight} = drawingElement;
        dispatch(
          updateDesign({canvasWidth: clientWidth, canvasHeight: clientHeight})
        );
      }
    };

    // Call once to set initial size
    updateDesignSize();

    // Set up event listener to update size when window is resized
    window.addEventListener('resize', updateDesignSize);

    // Clean up event listener when component is unmounted
    return () => {
      window.removeEventListener('resize', updateDesignSize);
    };
  }, [dispatch]);

  useEffect(() => {
    const fontNames = [
      'Roboto Slab',
      'Montserrat',
      'Bebas Neue',
      'Comfortaa',
      'Alfa Slab One',
      'Pacifico',
      'Roboto Mono',
      'Courier Prime',
      'Yellowtail'
    ];
    const fontObservers = fontNames.map(
      (fontName) => new FontFaceObserver(fontName)
    );

    Promise.all(fontObservers.map((observer) => observer.load()))
      .then(() => {
        setFontsLoaded(true);
      })
      .catch((error) => {
        console.error('Font loading error:', error);
      });
  }, []);

  useEffect(() => {
    setMeasurements(extractCanvasMeasurementsFromDesign(design));
  }, [design]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [currentKonvaObject]);

  const handleKeyDown = (e) => {
    if (currentKonvaObject) {
      switch (e.key) {
        case 'Delete':
        case 'Backspace':
          if (document.activeElement.type != 'text') {
            const index = design.objects.findIndex(
              (obj) => obj.id == currentKonvaObject.id
            );
            if (index > -1) {
              const objects = [...design.objects];
              objects.splice(index, 1);
              dispatch(updateDesign({objects: objects}));
            }
            dispatch(updateCurrentKonvaObject(null));
          }
          break;
        case 'ArrowLeft':
          e.preventDefault();
          moveSelectedX(-1);
          break;
        case 'ArrowRight':
          e.preventDefault();
          moveSelectedX(1);
          break;
        case 'ArrowDown':
          e.preventDefault();
          moveSelectedY(1);
          break;
        case 'ArrowUp':
          e.preventDefault();
          moveSelectedY(-1);
          break;
        default:
      }
    }
  };

  function moveSelectedX(value) {
    const deltaX = measurements.canvasWidth / 100 / measurements.tubeWidth;
    dispatch(
      updateCurrentKonvaObject({x: currentKonvaObject.x + deltaX * value})
    );
  }

  function moveSelectedY(value) {
    const deltaY = measurements.canvasHeight / 100 / measurements.tubeHeight;
    dispatch(
      updateCurrentKonvaObject({y: currentKonvaObject.y + deltaY * value})
    );
  }

  const checkDeselect = (e) => {
    const clickedOnNew = e.target.attrs?.id != currentKonvaObject?.id;
    if (clickedOnNew) {
      saveSelected();
      // deselect when clicked on empty area
      if (e.target == e.target.getStage())
        dispatch(updateCurrentKonvaObject(null));
    }
  };

  function saveSelected() {
    let canvasObject = textLayerRef.current.findOne(
      '#' + currentKonvaObject?.id
    );
    if (canvasObject == null)
      canvasObject = imageLayerRef.current.findOne(
        '#' + currentKonvaObject?.id
      );

    if (canvasObject == null) return;

    const index = design.objects.findIndex(
      (object) => object.id === currentKonvaObject?.id
    );
    const objects = [...design.objects];
    if (index === -1) {
      objects.push(currentKonvaObject);
      dispatch(updateDesign({objects: objects}));
    } else if (
      JSON.stringify(design.objects[index]) !==
      JSON.stringify(currentKonvaObject)
    ) {
      objects[index] = currentKonvaObject;
      dispatch(updateDesign({objects: objects}));
    }
  }

  return (
    <div className="drawing" id="background" style={{width: 876}}>
      {fontsLoaded ? (
        <Stage
          width={measurements.canvasWidth}
          height={measurements.canvasHeight}
          onMouseDown={checkDeselect}
          onTouchStart={checkDeselect}
          ref={stageRef}>
          <Provider store={store}>
            <TextLayer layerRef={textLayerRef} measurements={measurements} />
            <ImageLayer layerRef={imageLayerRef} measurements={measurements} />
          </Provider>
        </Stage>
      ) : (
        <div>Loading Fonts...</div>
      )}
    </div>
  );
};

export default DrawLayer;
