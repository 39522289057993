/* eslint-disable max-len */
import React from 'react';
import './Footer.scss';
import siteHTML from '../SiteSpecific/SiteSpecific';

const LabFooter = () => {
  return <footer className="hs-footer">{siteHTML('footer')}</footer>;
};

export default LabFooter;
