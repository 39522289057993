import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import appConfig from '../../../config';
import Fuse from 'fuse.js';
import {getArtwork, removeArtwork} from '../../../api.js';
import {
  initialState,
  applicationSelector,
  updateCurrentKonvaObject,
  doSingleUpload
} from '../../../slices/application';
import FileUpload from '../../FileUpload/FileUpload.jsx';
import CancelBtn from '../../Buttons/AddEditCancel';
import {extractCanvasMeasurementsFromDesign} from '../../../utils/utils';

const SelectArt = () => {
  const dispatch = useDispatch();
  const cdnUri = appConfig.cdnUri;
  const [artworks, setArtworks] = useState([]);
  const [query, setQuery] = useState('');
  const [filteredArtworks, setFilteredArtworks] = useState(artworks);
  const [artSrc, setArtSrc] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);
  const {
    design,
    currentKonvaObject,
    uuid,
    isArtUpdated,
    singleUploadCheck,
    recentUploads
  } = useSelector(applicationSelector);

  useEffect(() => {
    const fetchArtwork = async () => {
      const res = await getArtwork(uuid);
      if (res) {
        setArtworks(
          sortUserArtwork(res.data.user_artworks).concat(
            res.data.default_artworks
          )
        );
      }
    };
    fetchArtwork();
  }, [isArtUpdated, recentUploads]);

  useEffect(() => {
    if (singleUploadCheck) {
      selectId(
        `${cdnUri}/${artworks[0]?.processed_location}`,
        artworks[0]?.imageWidth,
        artworks[0]?.imageHeight
      );
    }
  }, [artworks]);

  useEffect(() => {
    if (singleUploadCheck) {
      setArtwork();
      dispatch(doSingleUpload(false));
    }
  }, [artSrc]);

  const {tubeWidth, tubeHeight, drawableWidth, drawableHeight, ellipseRX} =
    extractCanvasMeasurementsFromDesign(design);

  function compareUploadTimes(a, b) {
    if (a.uploadedAt < b.uploadedAt) {
      return 1;
    }
    if (a.uploadedAt > b.uploadedAt) {
      return -1;
    }
    return 0;
  }

  const sortUserArtwork = (list) => {
    return list.sort(compareUploadTimes);
  };

  const removeArtworkAndUpdateState = async (artworkId) => {
    await removeArtwork(artworkId);
    const newArtworks = artworks.filter(
      (artwork) => artwork.artwork_id !== artworkId
    );
    setArtworks(newArtworks);
  };

  const selectId = (src, imgWidth, imgHeight, artworkId) => {
    let width = imgWidth;
    let height = imgHeight;
    let stock = false;
    if (src.includes('default-clipart')) {
      const imageElement = document.getElementById(artworkId);
      width = imageElement.naturalWidth;
      height = imageElement.naturalHeight;
      stock = true;
    }
    setIsDisabled(false);
    setArtSrc({src: src, imgWidth: width, imgHeight: height, stock: stock});
  };

  // focus and other focus psuedo selectors don't quite work right here on Safari
  function focusSafari(e) {
    Array.from(document.querySelectorAll('.hs-form-art-image.focus')).forEach(
      (el) => el.classList.remove('focus')
    );
    e.currentTarget.className += ' focus';
  }

  function resize(img) {
    const resized = {src: img.src};

    // fit img within it + padding
    const imageAspect = img.imgWidth / img.imgHeight;
    const drawableAspect = drawableWidth / drawableHeight;
    if (drawableAspect > imageAspect) {
      // height constrained
      resized.imgHeight = Math.round(drawableHeight * 0.8);
      resized.imgWidth = Math.round(imageAspect * resized.imgHeight);
    } else {
      // width constrained
      resized.imgWidth = Math.round(drawableWidth * 0.8);
      resized.imgHeight = Math.round(resized.imgWidth / imageAspect);
    }
    return resized;
  }

  // Set selected image and continue to image editing page
  const setArtwork = () => {
    const resizedImage = resize(artSrc);

    const color = initialState.printColors.find(
      ({value}) => value === design.printColor
    ).rgb;

    // check if currentKonvaObject
    let id = design.lastId + 1;
    let x = ((tubeWidth - ellipseRX) * 0.5) / tubeWidth;
    let y = 0.5;
    let invert = true;
    let rotation = 0;
    let threshold = artSrc.stock ? 0.2 : 0.8;
    if (currentKonvaObject) {
      id = currentKonvaObject.id;
      x = currentKonvaObject.x;
      y = currentKonvaObject.y;
      invert = currentKonvaObject.invert;
      rotation = currentKonvaObject.rotation;
      threshold = currentKonvaObject.threshold;
    }

    let scaleX = 0.8;
    let scaleY = 0.8;
    const imageAspect = resizedImage.imgWidth / resizedImage.imgHeight;
    if (imageAspect > drawableWidth / drawableHeight) {
      scaleY = (scaleX * tubeWidth) / imageAspect / tubeHeight;
    } else {
      scaleX = (scaleY * tubeHeight * imageAspect) / tubeWidth;
    }

    const imageObject = {
      id: String(id),
      key: String(id),
      type: 'image',
      x: x,
      y: y,
      scaleX: scaleX,
      scaleY: scaleY,
      skewX: 0,
      skewY: 0,
      height: artSrc.imgHeight,
      width: artSrc.imgWidth,
      offsetX: artSrc.imgWidth / 2,
      offsetY: artSrc.imgHeight / 2,
      threshold: threshold, // they can't see this before adding to page
      url: resizedImage.src,
      rotation: rotation,
      fill: 'transparent',
      selectColor: color,
      invert: invert,
      isSelected: false
    };

    // set the selected image src, to be used on the edit img
    dispatch(updateCurrentKonvaObject(imageObject));
  };

  const searchArt = (query) => {
    const search = new Fuse(artworks, {keys: ['processed_location']});
    const results = search.search(query);

    // reformat results to match artworks
    return results.map(function (x) {
      return x.item;
    });
  };

  const filterArt = () => {
    if (query != null && query != '') setFilteredArtworks(searchArt(query));
    else setFilteredArtworks(artworks);
  };

  useEffect(() => {
    getArtwork();
  }, [uuid]);

  useEffect(() => {
    filterArt();
  }, [artworks, query]);

  return (
    <div className="hs-form-container">
      <div className="hs-form-header">
        <h2 className="hs-form-heading has-bottom-border">ADD ART</h2>
        <FileUpload
          content="Upload an Image"
          classes="btn uix-btn-outline-link btn-sm input-radius hs-form-heading-link"
          id="artUpload"
        />
      </div>
      <div className="hs-form-body set-overflow">
        <div className="hs-form-control-group search">
          <input
            type="text"
            className="form-control hs-form-control"
            id="PrintSearch"
            name="PrintSearch"
            defaultValue=""
            placeholder="Search for Artwork"
            onChange={(e) => setQuery(e.target.value)}
          />
        </div>

        <div className="hs-form-art-list">
          {filteredArtworks.map((art) => {
            return (
              <div
                className="hs-form-art-item"
                key={art.artwork_id}
                onClick={selectId.bind(
                  this,
                  `${cdnUri}/${art?.processed_location}`,
                  art?.imageWidth,
                  art?.imageHeight,
                  art?.artwork_id
                )}>
                <div className="hs-art-item-container">
                  {art.stock ? null : (
                    <button
                      type="button"
                      className="btn btn-outline-secondary hs-form-art-clear"
                      tabIndex="-1"
                      onClick={removeArtworkAndUpdateState.bind(
                        this,
                        art.artwork_id
                      )}>
                      <FontAwesomeIcon icon={['fas', 'times']} />
                    </button>
                  )}
                  <button
                    type="button"
                    onClick={focusSafari}
                    className={`btn btn-outline-secondary input-radius hs-form-art-image ${
                      art.stock ? 'stock-art-button' : ''
                    } ${
                      recentUploads.includes(art.artwork_id)
                        ? 'recent-artwork'
                        : ''
                    }`}>
                    <img
                      id={art.artwork_id}
                      alt={art.stock ? 'stock art' : 'user art'}
                      className={art.stock ? 'stock-art' : ''}
                      src={`${cdnUri}/${art?.processed_location}`}
                    />
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="hs-form-footer">
        <CancelBtn />
        <button
          type="submit"
          className="btn btn-primary btn-sm input-radius float-end"
          onClick={setArtwork}
          disabled={isDisabled}>
          Add Image
        </button>
      </div>
    </div>
  );
};

export default SelectArt;
