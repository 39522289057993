import axios from 'axios';
import appConfig from './config';
const maxAttempts = 3;
export const getUri = () => appConfig.serverUri;
const makeRequest = async (options, attempts = 0) => {
  let response;

  while (attempts < maxAttempts) {
    try {
      response = await axios(options);
      break;
    } catch (error) {
      attempts = handleNetworkError(error, attempts);
    }
  }

  return response;
};

const handleNetworkError = (error, attempts) => {
  if (error.message === 'Network Error' && !error.response) {
    console.log('Network error occurred, retrying...');
    attempts++;
    if (attempts === maxAttempts) {
      console.log('Max attempts reached, Network Error.');
    }
    return attempts;
  } else if (error.response) {
    // Check the status code and provide a more detailed error message
    switch (error.response.status) {
      case 400:
        console.log('Bad Request');
        break;
      case 404:
        console.log('Not Found');
        break;
      case 500:
        console.log('Internal Server Error');
        break;
      default:
        console.log('An error occurred');
    }

    // Only retry on certain types of errors
    if (error.response.status === 500) {
      attempts++;
      console.log(`Attempt ${attempts} failed. Retrying...`);
      return attempts;
    }
  }

  // If the error is not a network error or a 500 error, throw it
  throw error;
};

export const artUpload = async function uploadArtwork(filesArray, uuid) {
  const bodyFormData = new FormData();
  filesArray.forEach((file) => {
    bodyFormData.append('imageFiles', file);
  });

  const options = {
    method: 'post',
    headers: {
      Accept: 'application/json'
    },
    url: `${getUri()}/artworks/save/${uuid}`,
    data: bodyFormData
  };

  return await makeRequest(options);
};

export const saveDesignToDynamo = async (design) => {
  let attempts = 0;
  let res;

  while (attempts < maxAttempts) {
    try {
      res = await axios({
        method: 'PUT',
        headers: {'Content-Type': 'application/json'},
        url: `${getUri()}/designs/save`,
        data: design
      });
      break;
    } catch (error) {
      attempts = handleNetworkError(error, attempts);
    }
  }

  return res;
};

export const getPriceListForDesign = async (design) => {
  const params = {
    heatshrinkColor: design.heatshrinkColor,
    diameter: design.diameter,
    finish: 'Standard',
    shrinkRatio: design.shrinkRatio,
    length: Number(design.length)
  };
  let attempts = 0;
  let response;

  while (attempts < maxAttempts) {
    try {
      response = await axios.get(`${getUri()}/heatshrink/pricing`, {
        params: params
      });
      break;
    } catch (error) {
      attempts = handleNetworkError(error, attempts);
    }
  }

  return response;
};

export const getPriceForDesign = async (design, quantity) => {
  const params = {
    heatshrinkColor: design.heatshrinkColor,
    diameter: design.diameter,
    finish: design.finish,
    shrinkRatio: design.shrinkRatio,
    length: Number(design.length)
  };
  let attempts = 0;
  let response;

  while (attempts < maxAttempts) {
    try {
      response = await axios.get(`${getUri()}/heatshrink/pricing/${quantity}`, {
        params: params
      });
      break;
    } catch (error) {
      attempts = handleNetworkError(error, attempts);
    }
  }

  return response;
};

export const finalizeDesign = async (design, image, croppedImage) => {
  const body = new FormData();
  body.append('imageFile', image);
  body.append('croppedImageFile', croppedImage);
  let attempts = 0;
  let response;
  while (attempts < maxAttempts) {
    try {
      response = await axios({
        method: 'put',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data'
        },

        url: `${getUri()}/designs/finalize/${localStorage.getItem('lastDesign')}`,
        data: body
      });
      break;
    } catch (error) {
      attempts = handleNetworkError(error, attempts);
    }
  }

  return response;
};

export async function getOrders(uuid) {
  let attempts = 0;
  let response;

  while (attempts < maxAttempts) {
    try {
      response = await axios({
        method: 'GET',
        headers: {'Content-Type': 'application/json'},
        url: `${getUri()}/designs/get/orders/${uuid}`
      });
      break;
    } catch (error) {
      attempts = handleNetworkError(error, attempts);
    }
  }

  return response;
}

export async function loadDesign(designId) {
  let attempts = 0;
  let response;

  while (attempts < maxAttempts) {
    try {
      response = await axios({
        method: 'GET',
        headers: {'Content-Type': 'application/json'},
        url: `${getUri()}/designs/get/${designId}`
      });
      break;
    } catch (error) {
      attempts = handleNetworkError(error, attempts);
    }
  }

  return response;
}

export const getLatestDesign = async function (userUUID) {
  if (userUUID == null) return;
  let attempts = 0;
  let response;

  while (attempts < maxAttempts) {
    try {
      response = await axios.get(
        `${getUri()}/designs/get/latest/${userUUID}?designId=${localStorage.getItem('lastDesign')}`
      );
      break;
    } catch (error) {
      attempts = handleNetworkError(error, attempts);
    }
  }

  return response;
};

export const getDesignById = async function (designId) {
  if (designId == null) return;
  let attempts = 0;
  let response;

  while (attempts < maxAttempts) {
    try {
      response = await axios.get(`${getUri()}/designs/get/${designId}`);
      break;
    } catch (error) {
      attempts = handleNetworkError(error, attempts);
    }
  }

  return response;
};

export const getArtwork = async function (uuid) {
  let attempts = 0;
  let res;

  while (attempts < maxAttempts) {
    try {
      res = await axios.get(`${getUri()}/artworks/user-art/${uuid}`);
      break;
    } catch (error) {
      attempts = handleNetworkError(error, attempts);
    }
  }

  return res;
};

export const removeArtwork = async function (artworkId) {
  let attempts = 0;

  while (attempts < maxAttempts) {
    try {
      await axios.delete(`${getUri()}/artworks/delete/${artworkId}`);
      break;
    } catch (error) {
      attempts = handleNetworkError(error, attempts);
    }
  }
};

export const getCompat = async (
  design,
  mostRecentSelectedTubingAttribute = '',
  lastSelectedSecondaryTubingAttribute = '',
  lastSelectedPrimaryTubingAttribute = ''
) => {
  const params = {
    heatshrinkColor: design.heatshrinkColor,
    diameter: design.diameter,
    finish: 'Standard',
    shrinkRatio: design.shrinkRatio,
    length: design.length,
    lastSecondary: lastSelectedSecondaryTubingAttribute,
    lastPrimary: lastSelectedPrimaryTubingAttribute
  };

  let attempts = 0;
  let response;

  while (attempts < maxAttempts) {
    try {
      response = await axios.get(
        `${getUri()}/heatshrink/compatibility${mostRecentSelectedTubingAttribute}`,
        {
          params: params
        }
      );
      break;
    } catch (error) {
      attempts = handleNetworkError(error, attempts);
    }
  }

  return response;
};
