import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  initialState,
  requestAppTabUpdate,
  updatePrintTab,
  updateCurrentKonvaObject,
  updateDesign,
  applicationSelector,
  requestHeatshrinkCompatibilityFailed,
  requestHeatshrinkCompatibilitySuccess
} from '../../../slices/application';
import {extractCanvasMeasurementsFromDesign} from '../../../utils/utils';
import FileUpload from '../../FileUpload/FileUpload';
import ColorDropdown from '../../Dropdowns/Color/Color';
import {getCompat} from '../../../api';

const SelectOptions = () => {
  const {design, heatshrinkCompatibility} = useSelector(applicationSelector);
  const [printColorChoices, setPrintColorChoices] = useState([
    ...heatshrinkCompatibility.printColor
  ]);
  const [isPrintColorCompatible, setIsPrintColorCompatible] = useState(true);
  const {tubeWidth, tubeHeight, ellipseRX} =
    extractCanvasMeasurementsFromDesign(design);

  const dispatch = useDispatch();

  async function fetchCompat(heatshrinkCompatibility) {
    try {
      const response = await getCompat(design, '', '', 'heatshrinkColor');
      if (response) {
        const payload = response.data;
        // Don't update the state if the payload is the same as the current state
        if (
          payload &&
          (!heatshrinkCompatibility ||
            JSON.stringify(payload.printColor) !==
              JSON.stringify(heatshrinkCompatibility.printColor))
        ) {
          dispatch(requestHeatshrinkCompatibilitySuccess(payload));
        }
      }
    } catch (error) {
      console.log(error);
      dispatch(requestHeatshrinkCompatibilityFailed(error));
    }
  }

  useEffect(() => {
    // Make sure the ink colors are compatible when coming to this page directly
    fetchCompat(heatshrinkCompatibility);
    const filteredPrintColorChoices = [
      ...heatshrinkCompatibility.printColor
    ].sort(compare);
    setPrintColorChoices(filteredPrintColorChoices);

    // Check if the selected print color is compatible
    if (design.printColor && checkPrintColorCompatibility(design.printColor)) {
      setIsPrintColorCompatible(true);
    } else {
      setIsPrintColorCompatible(false);
    }
  }, [design, heatshrinkCompatibility]);

  function checkPrintColorCompatibility(color) {
    const compatibleColor = heatshrinkCompatibility.printColor.find(
      (choice) => choice.value === color
    );
    return compatibleColor ? compatibleColor.compatible : false;
  }

  function handleClickAppTab(btnName) {
    dispatch(requestAppTabUpdate(btnName));
  }

  function handleClickPrintTab(btnName) {
    dispatch(updatePrintTab(btnName));
  }

  const printColorSelect = (e) => {
    setIsPrintColorCompatible(true);
    dispatch(updateDesign({printColor: e.target.title}));
  };

  function colorRGBMatch(nameIn) {
    const selectColor = initialState.printColors.find(
      ({value}) => value === nameIn
    );
    const color = selectColor.rgb;
    const txtcolor = 'rgb(' + color + ')';
    return {name: nameIn, rgbString: color, color: txtcolor};
  }

  function handleAddText() {
    const newId = design.lastId + 1;
    const text = {
      id: newId,
      key: String(newId),
      type: 'text',
      x: ((tubeWidth - ellipseRX) * 0.5) / tubeWidth,
      y: 0.5,
      width: 6,
      offsetX: 3,
      height: 50,
      offsetY: 25,
      scaleX: 6 / tubeWidth,
      scaleY: 50 / tubeHeight,
      skewX: 0,
      skewY: 0,
      rotation: 0,
      fontSize: 50,
      fontFamily: 'Helvetica',
      fontStyle: 'Normal',
      textDecoration: 'Normal',
      fill: colorRGBMatch(design.printColor).color,
      text: ''
    };
    dispatch(updateCurrentKonvaObject(text));
  }

  // Function to sort dropdown items
  function compare(a, b) {
    if (a.value < b.value) {
      return -1;
    }
    if (a.value > b.value) {
      return 1;
    }
    return 0;
  }

  return (
    <div className="hs-form-container">
      <div className="hs-form-header">
        <h2 className="hs-form-heading">Choose Your Print</h2>
      </div>
      <div className="hs-form-body">
        <div className="hs-form-options">
          <div className="hs-form-options-row two-buttons">
            <button
              type="button"
              className="btn uix-btn-outline-default btn-sm input-radius themed-outline-button"
              onClick={() => handleAddText()}>
              <svg
                className="hs-svg-letter-t"
                enableBackground="new 0 0 512 512"
                version="1.1"
                viewBox="0 0 512 512"
                xmlSpace="preserve"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink">
                <path
                  className="hs-svg-inherit"
                  d="M33.3,6h445.4l0.7,149.2h-21.3c-7-53.4-27-89.5-60-108.3c-18.5-10.4-46.2-16.1-83.1-17v389.8 c0,27.2,4.8,45.3,14.3,54.2c9.5,8.9,29.3,13.4,59.4,13.4V506H125v-18.8c28.9,0,48.1-4.5,57.6-13.6c9.5-9,14.3-27.1,14.3-54.1V29.8 c-36.1,1-63.8,6.6-83.1,17c-35.4,19.3-55.4,55.4-60,108.3H32.6L33.3,6z"
                />
              </svg>
              <span>Add Text</span>
            </button>
            <button
              type="button"
              className="btn uix-btn-outline-default btn-sm input-radius themed-outline-button"
              onClick={() => handleClickPrintTab('select-art')}>
              <FontAwesomeIcon icon={['far', 'image']} />
              <span>Add Art</span>
            </button>
          </div>
          <div className="hs-form-options-row one-button">
            <FileUpload
              classes="btn uix-btn-outline-default btn-sm input-radius dashed themed-outline-button"
              content={
                <>
                  <FontAwesomeIcon icon={['fas', 'cloud-upload-alt']} />
                  <span>Upload a JPG or PNG</span>
                  <small>
                    DRAG &#38; DROP <br /> OR BROWSE YOUR COMPUTER
                  </small>
                </>
              }
              id="artworkUpload"
            />
          </div>
        </div>
        {/* <div className="text-center">
          <button
            type="button"
            className="btn btn-outline-primary btn-lg input-radius"
            onClick={resetDesign}>
            <span>Reset Design</span>
          </button>
        </div> */}
        <div>
          <ColorDropdown
            compatibilityPath="/printColor"
            choices={printColorChoices}
            enabled={true}
            handleSelect={printColorSelect}
            label="Print Color"
            placeholder="Red"
            required={true}
            showIncompatible={false}
          />
        </div>
        <div className="hs-form-control-group pt-0">
          <label
            className="form-label hs-form-label pt-0"
            style={{fontSize: '1em', fontStyle: 'italic'}}>
            (Select print on tubing to edit other settings)
          </label>
        </div>
      </div>
      <div className="hs-form-footer">
        <button
          type="button"
          className="btn btn-outline-primary btn-sm input-radius"
          onClick={() => handleClickAppTab('choose-tubing')}>
          <em>Previous</em>
        </button>
        {/* <button type="submit" className="btn btn-primary btn-sm input-radius float-end">Next</button> <---- not using as submit for now, maybe in a bit*/}
        <button
          type="button"
          className="btn btn-primary btn-sm input-radius float-end"
          onClick={() => handleClickAppTab('choose-quantity')}
          disabled={!isPrintColorCompatible} // Disable the button if the printColor is not compatible
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default SelectOptions;
