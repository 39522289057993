import {useState, useEffect} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import './Modal.scss';
import styled from 'styled-components';
import {useSelector} from 'react-redux';
import {applicationSelector} from '../../slices/application';

const Modal = ({
  header,
  body,
  footer,
  blurBackground,
  solidColor,
  textColor,
  align,
  show,
  closeHandler,
  width = '400px'
}) => {
  const [isVisible, setVisible] = useState(show);

  const {design} = useSelector(applicationSelector);
  const site = design.siteCode;

  useEffect(() => {
    setVisible(show);
  }, [show]);

  function closeModal() {
    setVisible(false);
    if (closeHandler) closeHandler();
  }

  return (
    <ModalContainer
      blurBackground={blurBackground}
      solidColor={solidColor}
      align={align}
      width={width}
      textColor={textColor}
      className={`modal fade hs-modal ${isVisible ? 'show' : ''}`}
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <ModalDialogDiv className="modal-dialog hs-modal-dialog">
        <div className={'modal-content hs-modal-content ' + site}>
          <ModalHeaderDiv
            solidColor={solidColor}
            className="modal-header hs-modal-header">
            <h5 className="modal-title hs-modal-title">{header}</h5>
            <button
              type="button"
              className="btn-close hs-modal-close"
              data-bs-dismiss="modal"
              aria-label="close"
              onClick={closeModal}>
              <FontAwesomeIcon
                style={{color: textColor}}
                icon={['fas', 'times']}
              />
            </button>
          </ModalHeaderDiv>

          <ModalBodyDiv
            solidColor={solidColor}
            align={align}
            className="modal-body hs-modal-body"
            style={{whiteSpace: 'pre-wrap'}}>
            {body}
          </ModalBodyDiv>

          <ModalFooterDiv
            solidColor={solidColor}
            className="modal-footer hs-modal-footer show">
            {footer}
          </ModalFooterDiv>
        </div>
      </ModalDialogDiv>
    </ModalContainer>
  );
};

export default Modal;

const ModalContainer = styled.div`
  background-color: ${(props) =>
    props.blurBackground ? 'rgba(255, 255, 255, 0.5)' : ''};
  color: ${(props) => (props.solidColor ? props.textColor : '#0a3357')};
`;
const ModalBodyDiv = styled.div`
  background-color: ${(props) => (props.solidColor ? '#0a3357' : '')};
  text-align: ${(props) => props.align};
`;
const ModalHeaderDiv = styled.div`
  background-color: ${(props) => (props.solidColor ? '#0a3357' : '')};
`;
const ModalFooterDiv = styled.div`
  background-color: ${(props) => (props.solidColor ? '#0a3357' : '')};
  margin: ${(props) => (props.solidColor ? '0' : 'inherit')};
`;
const ModalDialogDiv = styled.div`
  max-width: ${(props) => props.width};
  margin: 25vh auto;
`;
