/* eslint-disable max-len */
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  applicationSelector,
  requestHeatshrinkCompatibilityFailed,
  requestHeatshrinkCompatibilitySuccess,
  requestAppTabUpdate,
  updateDesign,
  updatePrintTab
} from '../../../slices/application';
import HspDropdown from '../../Dropdowns/HspDropdown/HspDropdown';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import '../../Tooltip/Tooltip.scss';
import {Dropdown} from 'react-bootstrap';
import * as util from '../../../utils/utils';
import {getCompat} from '../../../api.js';

const ChooseTubing = ({compatibility}) => {
  if (!compatibility) return null;
  const dispatch = useDispatch();
  const {heatshrinkTubeLengths, design, heatshrinkCompatibility} =
    useSelector(applicationSelector);
  if (!design) return null;

  const {
    lastSelectedPrimaryTubingAttribute,
    lastSelectedSecondaryTubingAttribute,
    mostRecentSelectedTubingAttribute
  } = design;
  const [lastSelected, setLastSelected] = useState('');
  const [selectionUpdate, setSelectionUpdate] = useState({
    diameter: design.diameter,
    finish: 'Standard',
    shrinkRatio: design.shrinkRatio,
    heatshrinkColor: design.heatshrinkColor,
    length: design.length
  });
  const [isCompatible, setIsCompatible] = useState(
    compatibility.heatshrinkSelectionIsCompatible
  );
  const [diameterChoices, setDiameterChoices] = useState(
    compatibility.diameter
  );
  const [shrinkRatioChoices, setShrinkRatioChoices] = useState(
    compatibility.shrinkRatio
  );
  const [heatshrinkColorChoices, setHeatshrinkColorChoices] = useState(
    compatibility.heatshrinkColor
  );
  const [compatibilityLookup, setCompatibilityLookup] = useState({
    diameter: true,
    heatshrinkColor: true,
    shrinkRatio: true
  });

  // Updates the list of selected parameters whenever a dropdown item is chosen
  useEffect(() => {
    const newSelection = {
      heatshrinkColor: design.heatshrinkColor,
      diameter: design.diameter,
      finish: 'Standard',
      shrinkRatio: design.shrinkRatio,
      length: design.length
    };
    setSelectionUpdate(newSelection);
  }, [
    design.heatshrinkColor,
    design.diameter,
    design.shrinkRatio,
    design.length
  ]);

  async function fetchCompat() {
    try {
      const payload = await getCompat(
        design,
        mostRecentSelectedTubingAttribute,
        lastSelectedSecondaryTubingAttribute,
        lastSelectedPrimaryTubingAttribute
      );
      if (payload && payload.data) {
        dispatch(requestHeatshrinkCompatibilitySuccess(payload.data));
      }
    } catch (error) {
      dispatch(requestHeatshrinkCompatibilityFailed(error));
    }
  }

  // Requests new compatibility list from microservice whenever the list of selections gets updated
  useEffect(() => {
    fetchCompat();
  }, [selectionUpdate]);

  function getChoiceList(type) {
    switch (type) {
      case 'diameter':
        return diameterChoices;
      case 'shrinkRatio':
        return shrinkRatioChoices;
      case 'heatshrinkColor':
        return heatshrinkColorChoices;
      default:
        return [];
    }
  }

  function isChoiceCompatible(type) {
    const list = getChoiceList(type);
    const compatibleCheck = list.find((obj) => {
      return obj.value == selectionUpdate[type];
    });
    // prevent null errors
    if (compatibleCheck) {
      return compatibleCheck.compatible;
    }
  }

  function updateCompatibilityLookup() {
    setCompatibilityLookup({
      diameter: isChoiceCompatible('diameter'),
      heatshrinkColor: isChoiceCompatible('heatshrinkColor'),
      shrinkRatio: isChoiceCompatible('shrinkRatio')
    });
  }

  // Updates dropdown choices whenever the compatibility list updates
  useEffect(() => {
    setDiameterChoices([...compatibility.diameter].sort(compare));
    setShrinkRatioChoices([...compatibility.shrinkRatio].sort(compare));
    setHeatshrinkColorChoices([...compatibility.heatshrinkColor].sort(compare));
    setIsCompatible(compatibility.heatshrinkSelectionIsCompatible);
  }, [compatibility, design]);

  useEffect(() => {
    updateCompatibilityLookup();
  }, [
    selectionUpdate,
    diameterChoices,
    heatshrinkColorChoices,
    shrinkRatioChoices
  ]);

  const handleSelect = (e) => {
    const type = e.target.dataset.type;
    const data = {};
    if (type != 'length') {
      setLastSelected(type);
      data.lastSelectedPrimaryTubingAttribute = type;
      data.mostRecentSelectedTubingAttribute = `/${type}`;
    }
    dispatch(
      updateDesign({
        lastSelectedPrimaryTubingAttribute: type,
        mostRecentSelectedTubingAttribute: `/${type}`
      })
    );

    if (type == 'diameter' || type == 'length')
      data[type] = Number(e.target.dataset.value);
    else data[type] = e.target.dataset.value;

    dispatch(updateDesign(data));
  };

  // Function to sort dropdown items
  function compare(a, b) {
    if (a.value < b.value) {
      return -1;
    }
    if (a.value > b.value) {
      return 1;
    }
    return 0;
  }

  function getTooltipMessage(option) {
    let tooltipMessage = 'Incompatible with ';
    if (option.incompatible) {
      const translation = {
        shrinkRatio: 'ShrinkRatio',
        diameter: 'Diameter',
        heatshrinkColor: 'Color',
        undefined: 'other selected options'
      };
      for (let i = 0; i < option.incompatible.length - 1; i++) {
        tooltipMessage += `${translation[option.incompatible[i]]}, `;
      }
      tooltipMessage += `${
        translation[option.incompatible[option.incompatible.length - 1]]
      }`;
    }
    return tooltipMessage;
  }

  function getDisplayValue(type, dataValue) {
    if (type == 'diameter' || type == 'length')
      return util.convertDiameterToText(dataValue);
    else return dataValue;
  }

  function getColorDropdownMenuItems(type, options) {
    return options.map((option) => {
      return (
        <OverlayTrigger
          placement="right"
          overlay={
            !option.compatible ? (
              <Tooltip>{getTooltipMessage(option)}</Tooltip>
            ) : (
              <></>
            )
          }
          key={option.value}>
          <Dropdown.Item
            onClick={handleSelect}
            key={option.value}
            className={`dropdown-item ${
              option.value === design[type] ? 'active' : ''
            } ${!option.compatible ? 'not-compatible' : ''}`}
            title={option.value}
            data-value={option.value}
            data-type={type}>
            <span
              className={`badge hs-form-badge bg-${option.value.toLowerCase()}`}></span>
            {getDisplayValue(type, option.value)}
          </Dropdown.Item>
        </OverlayTrigger>
      );
    });
  }

  function getDropdownMenuItems(type, options) {
    return options.map((option) => {
      return (
        <OverlayTrigger
          placement="right"
          overlay={
            !option.compatible ? (
              <Tooltip>{getTooltipMessage(option)}</Tooltip>
            ) : (
              <></>
            )
          }
          key={option.value}>
          <Dropdown.Item
            onClick={handleSelect}
            key={option.value}
            className={`dropdown-item ${
              option.value == design[type] ? 'active' : ''
            } ${!option.compatible ? 'not-compatible' : ''}`}
            title={option.value}
            data-value={option.value}
            data-type={type}>
            {getDisplayValue(type, option.value)}
          </Dropdown.Item>
        </OverlayTrigger>
      );
    });
  }

  // UI returned
  return (
    <form className="hs-form choose-tubing">
      <div className="hs-form-container">
        <div className="hs-form-header">
          <h2 className="hs-form-heading">Choose Your Tubing</h2>
        </div>
        <div className="hs-form-body">
          <HspDropdown
            label={{
              labelContent: 'Diameter (D)',
              labelKey: 'diameter'
            }}
            topLevelClassNames={
              !isCompatible &&
              heatshrinkCompatibility?.safestActions?.includes('diameter') &&
              lastSelected != 'diameter'
                ? 'has-error'
                : ''
            }
            dropdownMenuItems={getDropdownMenuItems(
              'diameter',
              diameterChoices
            )}
            dropdownToggleItems={{
              isDropdownActive: false,
              dropdownText: getDisplayValue('diameter', design.diameter),
              dropdownClasses:
                !compatibilityLookup['diameter'] && lastSelected != 'diameter'
                  ? 'not-compatible'
                  : ''
            }}
          />

          <HspDropdown
            label={{
              labelContent: 'Length (L)',
              labelKey: 'length'
            }}
            dropdownMenuItems={getDropdownMenuItems(
              'length',
              heatshrinkTubeLengths && [...heatshrinkTubeLengths].sort(compare)
            )}
            dropdownToggleItems={{
              isDropdownActive: false,
              dropdownText: getDisplayValue('length', design.length),
              dropdownClasses: ''
            }}
          />

          <HspDropdown
            label={{
              labelContent: 'Shrink Ratio (R)',
              labelKey: 'shrinkRatio'
            }}
            topLevelClassNames={
              !isCompatible &&
              heatshrinkCompatibility?.safestActions?.includes('shrinkRatio') &&
              lastSelected != 'shrinkRatio'
                ? 'has-error'
                : ''
            }
            dropdownMenuItems={getDropdownMenuItems(
              'shrinkRatio',
              shrinkRatioChoices
            )}
            dropdownToggleItems={{
              isDropdownActive: false,
              dropdownText: getDisplayValue('shrinkRatio', design.shrinkRatio),
              dropdownClasses:
                !compatibilityLookup['shrinkRatio'] &&
                lastSelected != 'shrinkRatio'
                  ? 'not-compatible'
                  : ''
            }}
          />

          <HspDropdown
            label={{
              labelContent: 'Tubing Color',
              labelKey: 'color'
            }}
            topLevelClassNames={
              !isCompatible &&
              heatshrinkCompatibility?.safestActions?.includes(
                'heatshrinkColor'
              ) &&
              lastSelected != 'heatshrinkColor'
                ? 'has-error'
                : ''
            }
            dropdownMenuItems={getColorDropdownMenuItems(
              'heatshrinkColor',
              heatshrinkColorChoices
            )}
            dropdownToggleItems={{
              isDropdownActive: false,
              dropdownText: getDisplayValue(
                'heatshrinkColor',
                design.heatshrinkColor
              ),
              dropdownClasses:
                !compatibilityLookup['heatshrinkColor'] &&
                lastSelected != 'heatshrinkColor'
                  ? 'not-compatible'
                  : ''
            }}
            includeSwatch={true}
          />
        </div>

        <div className="hs-form-footer">
          <button
            type="button"
            className="btn btn-primary btn-sm input-radius float-end"
            onClick={() => {
              // fetchCompat();
              dispatch(requestAppTabUpdate('choose-print'));
              dispatch(updatePrintTab('select-options'));
            }}
            disabled={!isCompatible}>
            Next
          </button>
        </div>
      </div>
    </form>
  );
};

export default ChooseTubing;
