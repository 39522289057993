import {useEffect, useState, createRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {applicationSelector, updateDesign} from '../../slices/application';
import CanvasFooter from './CanvasFooter';
import CanvasHeader from './CanvasHeader';
import DrawLayer from './DrawLayer';
import SelectTubeLayer from './SelectTubeLayer';
import './SelectTubeLayer.scss';
import TubeLayerCanvas from './TubeLayerCanvas';

function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    }

    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

const Canvas = () => {
  const ref = createRef(null);
  const dispatch = useDispatch();

  const size = useWindowSize();

  useEffect(() => {
    // Check if size has non-zero width and height
    if (size.width > 0 && size.height > 0) {
      updateDesignWithCanvasSize();
    }
  }, [size]);

  function updateDesignWithCanvasSize() {
    let drawing = document.getElementsByClassName('drawing')[0];

    // If the class name 'drawing' is not found, do not proceed with the function
    if (!drawing) {
      return;
    }
    // Add a counter to prevent infinite loop
    let counter = 0;
    while (
      (drawing?.clientHeight === 0 || drawing?.clientHeight == null) &&
      appTab == 'select-options' &&
      counter < 100
    ) {
      drawing = document.getElementsByClassName('drawing')[0];
      counter++;
    }

    // Check if the canvas has a non-zero width and height
    if (drawing?.clientHeight > 0 && drawing?.clientWidth > 0) {
      dispatch(
        updateDesign({
          canvasHeight: drawing.clientHeight,
          canvasWidth: drawing.clientWidth
        })
      );
    } else {
      console.error(
        'Cannot update design with a canvas of zero width or height'
      );
    }
  }

  const {appTab} = useSelector(applicationSelector);
  return (
    <div className="hs-canvas">
      <CanvasHeader />
      <div className="hs-canvas-wrapper" ref={ref}>
        {appTab === 'choose-tubing' ? <SelectTubeLayer /> : null}
        {appTab === 'choose-print' ||
        appTab === 'select-art' ||
        appTab === 'choose-quantity' ? (
          <>
            <TubeLayerCanvas />
            <DrawLayer />
          </>
        ) : null}
      </div>
      <CanvasFooter />
    </div>
  );
};

export default Canvas;
