import {useDispatch, useSelector} from 'react-redux';
import {applicationSelector, updateInfoModal} from '../../slices/application';
import Modal from './Modal';

const InfoModal = () => {
  const dispatch = useDispatch();
  const {isInfoModalVisible, infoText} = useSelector(applicationSelector);

  function closeModal() {
    dispatch(updateInfoModal({infoText: '', isInfoModalVisible: false}));
  }

  return (
    <Modal
      closeHandler={closeModal}
      show={isInfoModalVisible}
      blurBackground={true}
      solidColor={true}
      align="center"
      textColor="white"
      body={infoText}
      footer={
        <>
          <button
            type="button"
            className="btn btn-secondary btn-sm input-radius"
            data-bs-dismiss="modal"
            onClick={closeModal}>
            Close
          </button>
        </>
      }
    />
  );
};

export default InfoModal;
