import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  applicationSelector,
  requestAppTabUpdate
} from '../../slices/application';
import './Nav.scss';

const LabNav = () => {
  const dispatch = useDispatch();
  const {design, heatshrinkCompatibility, appTab} =
    useSelector(applicationSelector);
  const [isCompatible, setIsCompatible] = useState(
    heatshrinkCompatibility.heatshrinkSelectionIsCompatible &&
      isPrintColorCompatible()
  );

  useEffect(() => {
    setIsCompatible(
      heatshrinkCompatibility.heatshrinkSelectionIsCompatible &&
        isPrintColorCompatible()
    );
  }, [heatshrinkCompatibility, design]);

  function isPrintColorCompatible() {
    return heatshrinkCompatibility.printColor.find(
      (color) => color.value === design.printColor
    ).compatible;
  }

  function handleClick(btnName) {
    if (btnName !== appTab) {
      // change app tab
      dispatch(requestAppTabUpdate(btnName));
    }
  }

  return (
    <nav className="hs-nav">
      <button
        type="button"
        aria-label={'choose tubing button'}
        className={`btn btn-link hs-nav-item ${
          appTab === 'choose-tubing' ? 'active' : ''
        }`}
        onClick={() => handleClick('choose-tubing')}>
        <svg
          className="hs-svg-heat-shrink"
          enableBackground="new 0 0 512 512"
          version="1.1"
          viewBox="0 0 512 512"
          xmlSpace="preserve"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink">
          <path
            className="hs-svg-inherit"
            d="M432,208.1L432,208.1L136.2,504 c-4,4-14.5,1-29.5-8.3c-18.1-11.9-34.8-25.6-49.9-41.1C26.7,424.5,5.7,394,5.7,380.3c-0.1-1.9,0.5-3.7,1.7-5.1l297-297 c11.5,29.6,29.8,56,53.5,77.1C378.5,178.1,403.8,196.1,432,208.1L432,208.1z"
          />
          <path
            className="hs-svg-inherit"
            d="M490.9,85L490.9,85l-63.5,63.5c10.7,11.1,18.3,24.7,22.3,39.5c0.1,1.4-0.4,2.7-1.3,3.8 c-3.4,3.4-13.4,0-28-9.8c-17.7-12.3-34.2-26.3-49.3-41.7c-30.6-30.6-52.8-61.5-52.8-73.5c-0.1-1.4,0.4-2.7,1.3-3.8 c4.6-4.6,21.2,3.5,43.3,21.1l63.5-63.5c7.8,13.2,17.3,25.4,28.3,36.1C465.5,67.7,477.6,77.2,490.9,85L490.9,85z"
          />
          <ellipse
            className="hs-svg-inherit"
            transform="matrix(0.7071 -0.7071 0.7071 0.7071 111.1962 345.6559)"
            cx="472.8"
            cy="38.6"
            rx="9.1"
            ry="45.5"
          />
        </svg>
      </button>
      <button
        type="button"
        aria-label={'choose print button'}
        className={`btn btn-link hs-nav-item ${
          appTab === 'choose-print' ? 'active' : ''
        }`}
        onClick={() => handleClick('choose-print')}
        disabled={!isCompatible && appTab !== 'choose-print'}>
        <svg
          className="hs-svg-print"
          enableBackground="new 0 0 512 512"
          version="1.1"
          viewBox="0 0 512 512"
          xmlSpace="preserve"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink">
          <path
            className="hs-svg-inherit"
            d="M95.4,425.6c-23.4,0-42.5-19-42.5-42.5V270h42.5v107.9c0,2.9,2.4,5.3,5.3,5.3H458 c2.9,0,5.3-2.4,5.3-5.3V133.8c0-2.9-2.4-5.3-5.3-5.3H166.1V86h297.2c23.4,0,42.5,19,42.5,42.5v254.7c0,23.4-19,42.5-42.5,42.5 L95.4,425.6z M420.8,340.7h-283v-42.5l35-35c2-2,4.7-3.1,7.5-3.1c2.8,0,5.5,1.1,7.5,3.1l35,35l105.7-105.7c2-2,4.7-3.1,7.5-3.1 c2.8,0,5.5,1.1,7.5,3.1l77.4,77.4V340.7L420.8,340.7z M34.1,238.8v-5.7c6.2,0.7,12.4-0.7,17.6-4.1c3.5-4.7,5.1-10.7,4.4-16.5V93.3 c-8.8-0.2-17.5,1.6-25.5,5.2c-11.5,7-18.5,19.6-18.4,33.1H5.8L6,85.9h136.3l0.2,45.7H136c-0.4-13.3-7.3-25.7-18.4-33.1 c-8-3.7-16.7-5.5-25.5-5.2v119.2c-0.7,5.9,0.8,11.8,4.4,16.6c5.4,3.4,11.8,4.8,18.2,4.1v5.7L34.1,238.8z M187.4,199.2 c0-19.5,15.8-35.4,35.4-35.4s35.4,15.8,35.4,35.4s-15.8,35.4-35.4,35.4S187.4,218.8,187.4,199.2z"
          />
        </svg>
      </button>
      <button
        type="button"
        aria-label={'choose quantity button'}
        className={`btn btn-link hs-nav-item ${
          appTab === 'choose-quantity' ? 'active' : ''
        }`}
        onClick={() => handleClick('choose-quantity')}
        disabled={!isCompatible}>
        <svg
          className="hs-svg-dollar-circle"
          enableBackground="new 0 0 512 512"
          version="1.1"
          viewBox="0 0 512 512"
          xmlSpace="preserve"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink">
          <path d="M255.8,5.7c138,0.3,245.6,110.1,249.8,241.8c4.5,140.3-107.2,258.5-250.5,258.1C117,505.2,10.1,395.1,5.9,263.8 C1.4,123.5,113.1,5.9,255.8,5.7z M457,258.3C458,146.2,369.4,56,256.8,54.5C147.6,53,55.6,143.4,54.6,253.3 c-1,111.5,88.4,202.6,199.9,203.5C364.6,457.6,456,368.1,457,258.3z" />
          <path d="M280.4,126.8c14.6,0.6,27.5,4.4,39.6,10.8c3.4,1.8,6.8,4,9.6,6.7c6.5,6.2,7,14.9,1.1,21.7c-4.4,5-9.2,9.7-14.4,13.9 c-5.6,4.5-12.2,4.5-18.3,1.2c-8.2-4.4-16.9-5.9-26-5.8c-12.4,0.1-24.7,0-37.1,0.1c-12.8,0-21.1,7.1-23.1,19.7 c-1.8,11.7,5,23.2,16.3,26.7c22.2,6.9,44.4,14.3,66.9,20.2c53.5,14,72.3,82.1,33.1,122.4c-11.3,11.6-25.1,18.8-41.4,19.9 c-5,0.3-6.8,1.9-6.4,7c1.7,22.6-7.2,27-25.9,25.7c-4.5-0.3-9.2-0.2-13.3-1.7c-6.4-2.4-9.4-8-9.6-14.8c-0.1-5.1,0-10.1,0-14.1 c-11.7-3.4-22.6-6.2-33.2-9.8c-4.9-1.7-9.6-4.5-13.8-7.7c-8.9-6.7-9.6-17-2-25.1c3.9-4.1,8-8.1,12.4-11.7c5.7-4.8,12.3-5.4,18.7-2 c9,4.9,18.4,6.2,28.4,6c11.8-0.2,23.5,0,35.3-0.1c12.8-0.1,21.1-7.4,23-19.8c1.8-11.6-5.2-23.1-16.5-26.6 c-23.2-7.2-46.5-14.1-69.7-21.2c-31.5-9.7-51.8-38.2-51-71.7c0.7-32.5,22.4-60.4,53.1-68.2c4.7-1.2,9.6-1.5,15.5-2.4 c0-4.3,0-8.7,0-13.1c0.1-12.2,6.1-18.5,18.3-18.8c5.5-0.1,11-0.2,16.4,0.5c8.3,0.9,13.7,7.2,14,15.5 C280.5,115.5,280.4,120.8,280.4,126.8z" />
        </svg>
      </button>
    </nav>
  );
};

export default LabNav;
