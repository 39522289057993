import React from 'react';
import {useSelector} from 'react-redux';
import {applicationSelector} from '../../slices/application';

const siteHTML = (param) => {
  const {design} = useSelector(applicationSelector);
  const site = design.siteCode;

  function getHTML(section) {
    if (site == 'TF') {
      return techflexSection(section);
    } else if (site == 'WC') {
      return wirecareSection(section);
    } else {
      // default to Wirecare
      return wirecareSection(section);
    }
  }

  function techflexSection(section) {
    switch (section) {
      case 'footer':
        return tfFooter;
      case 'name':
        return 'Techflex®';
      case 'disclaimer':
        return tfDisclaimer;
      default:
        return '';
    }
  }

  function wirecareSection(section) {
    switch (section) {
      case 'footer':
        return wcFooter;
      case 'name':
        return 'WireCare®';
      case 'disclaimer':
        return wcDisclaimer;
      default:
        return '';
    }
  }

  const wcFooter = (
    <>
      <p>
        WireCare® is happy to make this unique service available to our
        customers! Our proprietary heatshrink application allows you to select
        from our available combinations of heatshrink products and design your
        own customized heatshrink tubing. We offer a constantly expanding
        library of stock images, as well as text capabilities, to place on your
        tubing. You can even upload your company logo or other custom art to be
        saved into our database for your use in the future. Your finished art
        will be prepared by our Graphics Dept. and printed on our high
        resolution equipment.
      </p>
      <p>
        Please check out our&nbsp;
        <a
          className="link-style"
          href="https://www.wirecare.com/university/guides/heat-shrink-printing"
          target="_blank"
          rel="noreferrer">
          Heat Shrink Printing Guide
        </a>
        &nbsp; for instructions on how to make your next Printed Heatshrink job
        turn out great!
      </p>
    </>
  );

  const wcDisclaimer = (
    <small>
      By placing your Heatshrink Printing order, you are giving implied consent
      for WireCare® to use samples of your designs for promotional purposes. If
      you wish to opt out your heatshrink designs for these uses, please let us
      know via phone at &nbsp;
      <a href="tel:1-844-294-7322">1-844-2WIRECARE (1-844-294-7322)</a> or via
      email at &nbsp;
      <a href="mailto:info@wirecare.com">info@Wirecare.com</a>.
    </small>
  );

  const tfFooter = (
    <>
      <div className="container">
        <div className="row">
          <div className="col">
            <p>
              <span lang="en-us">
                One of your exclusive benefits of being a Techflex® B2B member
                is total access to our Techflex® Heatshrink Designer program.
                We are pleased to make this unique service available to you,
                with a newly redesigned user experience that gives you full
                control of your design.
              </span>
            </p>
            <p>
              <span lang="en-us">
                You can select from our available heatshrink products, then
                create customized heatshrink tubing from our generous selection
                of heatshrink types and colors. Next, you choose coordinating
                ink colors (black, blue, gold, green, orange, purple, red,
                silver, white, or yellow). You also have the option to submit
                high quality images that will be imprinted on the heatshrink you
                design.
              </span>
            </p>
            <p>
              <span lang="en-us">
                To get the best heatshrink printing results, please follow the
                guidelines in&nbsp;
                <a
                  className="link-style"
                  href="//cdn.techflex.com/assets/pdfs/how-to/heat-shrink-printing.pdf"
                  rel="noreferrer"
                  target="_blank">
                  Printing With Heatshrink Designer.
                </a>
              </span>
            </p>
            <p>
              <span lang="en-us">
                Have additional questions about using Techflex® Heatshrink
                Designer? Contact your sleeving specialist about this program,
                or many of our other B2B perks.
              </span>
            </p>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col px-0">
            <img
              className="img-responsive flex-width-fix"
              src="//cdn.techflex.com/assets/images/banners/heatshrink-printing-art.jpg"
              alt="heatshrink printing art"
              style={{width: '100%'}}></img>
          </div>
        </div>
      </div>
    </>
  );

  const tfDisclaimer = (
    <small>
      By placing your Heatshrink Printing order, you are giving implied consent
      for Techflex® to use samples of your designs for promotional purposes. If
      you wish to opt out your heatshrink designs for these uses, please let us
      know via phone at &nbsp;
      <a href="tel:1-833-753-3846">1-833-SLEEVING (1-833-753-3846)</a> or via
      our contact form at &nbsp;
      <a href="https://www.techflex.com/contacts">Contact Us</a>.
    </small>
  );

  return getHTML(param);
};

export default siteHTML;
