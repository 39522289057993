import React, {useRef, useEffect, useState} from 'react';
import {Text, Transformer} from 'react-konva';
import {useDispatch, useSelector} from 'react-redux';
import {
  updateCurrentKonvaObject,
  applicationSelector,
  doAutoSelect
} from '../../slices/application';
import './DrawLayer.scss';

const TextObject = ({textObject, measurements, handleChange}) => {
  const dispatch = useDispatch();
  const {canvasHeight, padding, tubeWidth, tubeHeight, inset} = measurements;
  const textRef = useRef();
  const textTransformerRef = useRef();

  const {printColor, currentKonvaObject} = useSelector(applicationSelector);
  const [isSelected, setSelected] = useState(
    currentKonvaObject?.id === textObject.id
  );

  const [textObjectClone, setTextObjectClone] = useState(
    isSelected ? currentKonvaObject : textObject
  );
  useEffect(() => {
    setTextObjectClone(textObject);
  }, [textObject]);
  useEffect(() => {
    if (currentKonvaObject === null) setTextObjectClone(textObject);
    if (currentKonvaObject?.id === textObject.id) {
      setTextObjectClone(currentKonvaObject);
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [currentKonvaObject]);

  useEffect(() => {
    if (isSelected) {
      //move transform box to front so it doesn't hide behind other text/images
      textTransformerRef.current.moveToTop();
      // we need to attach transformer manually
      textTransformerRef.current.nodes([textRef.current]);
      textTransformerRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  function selectHandler(e) {
    if (!isSelected) {
      e.target.moveToTop();
      e.target.getLayer().moveToTop();
      setSelected(true);
      dispatch(updateCurrentKonvaObject(textObject));
    }
    //Move text and layer to front so it can be dragged
  }

  function doubleClickHandler() {
    dispatch(doAutoSelect());
  }

  return (
    <>
      <Text
        onMouseUp={selectHandler}
        onTap={selectHandler}
        onDblClick={doubleClickHandler}
        ref={textRef}
        {...textObjectClone}
        id={String(textObjectClone.id)}
        fill={printColor.textColor}
        x={textObjectClone.x * tubeWidth + inset + padding}
        y={(canvasHeight - tubeHeight) / 2 + textObjectClone.y * tubeHeight}
        scaleX={(textObjectClone.scaleX * tubeWidth) / textObjectClone.width}
        scaleY={(textObjectClone.scaleY * tubeHeight) / textObjectClone.height}
        textWidth={textObjectClone.width}
        textHeight={textObjectClone.height}
        fontSize={50}
        draggable={isSelected}
        onTransformEnd={() => {
          handleChange(textObjectClone, textRef.current);
        }}
        onDragEnd={() => {
          handleChange(textObjectClone, textRef.current);
        }}
      />
      {isSelected && (
        <Transformer
          ref={textTransformerRef}
          rotateEnabled={false}
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
    </>
  );
};

export default TextObject;
