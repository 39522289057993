/* eslint-disable max-len */
/* eslint-disable react/jsx-key */
import React, {useEffect, useState} from 'react';
import {Dropdown} from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import {useDispatch, useSelector} from 'react-redux';
import {applicationSelector, updateDesign} from '../../../slices/application';
const Color = ({
  choices,
  compatibilityPath,
  enabled = false,
  handleSelect,
  label,
  required = false,
  showIncompatible = true
}) => {
  const dispatch = useDispatch();
  const {design, heatshrinkCompatibility} = useSelector(applicationSelector);
  const {mostRecentSelectedTubingAttribute} = design;
  const [designAttribute, setDesignAttribute] = useState(
    compatibilityPath === '/heatshrinkColor'
      ? design.heatshrinkColor
      : design.printColor != null
        ? design.printColor
        : 'Red'
  );
  const [selectedObject, setSelectedObject] = useState(
    choices.filter((obj) => {
      return obj.value === designAttribute;
    })
  );
  const [toggled, setToggled] = useState(false);

  function blurOut() {
    setToggled(false);
    dispatch(updateDesign({}));
  }

  useEffect(() => {
    setDesignAttribute(
      compatibilityPath === '/heatshrinkColor'
        ? design.heatshrinkColor
        : design.printColor != null
          ? design.printColor
          : 'Red'
    );
  }, [design]);

  useEffect(() => {
    setSelectedObject(
      choices.filter((obj) => {
        return obj.value === designAttribute;
      })
    );
  }, [designAttribute]);

  useEffect(() => {
    document.getElementById(compatibilityPath).classList.remove('has-error');
    if (
      !(
        mostRecentSelectedTubingAttribute === compatibilityPath ||
        choices.filter((obj) => {
          return obj.value === selectedObject[0]?.value;
        })[0]?.compatible
      )
    ) {
      document.getElementById(compatibilityPath)?.classList.add('has-error');
    }
  }, [heatshrinkCompatibility]);

  function selectionClick(e) {
    const newSelectedObject = choices.filter((obj) => {
      return obj.value === e.target.innerText;
    });

    if (colorIncompatible(e.target.innerText)) {
      alert(
        'Sorry, that print color is incompatible with your heatshrink color'
      );
      return;
    }
    setSelectedObject(newSelectedObject);
    dispatch(updateDesign({}));
    handleSelect(e);
  }

  function handleKeyDown(e) {
    if (e.key == 'Enter') {
      selectionClick(e);
      blurOut();
    }
  }

  function colorIncompatible(colorselected) {
    const check = heatshrinkCompatibility['printColor'].find(
      (x) => x.value == colorselected
    );
    return check ? !check.compatible : true;
  }

  return (
    <div
      id={`${compatibilityPath}`}
      className={`hs-form-control-group pt-0 ${
        mostRecentSelectedTubingAttribute !== compatibilityPath
          ? !choices.filter((obj) => {
              return obj.value === selectedObject[0]?.value;
            })[0]?.compatible
            ? 'has-error'
            : ''
          : ''
      }`}>
      <label htmlFor={label} className="form-label hs-form-label">
        {required ? '' : <em>Optional</em>}
        {label}
        <strong>Incompatible</strong>
      </label>
      <Dropdown className="dropdown hs-form-dropdown">
        <Dropdown.Toggle
          className={`btn uix-btn-outline-default btn-sm input-radius dropdown-toggle hs-form-dropdown-toggle ${
            !selectedObject[0]?.compatible ? 'not-compatible' : ''
          }`}
          type="button"
          id={label}
          disabled={!enabled}
          title={selectedObject[0]?.value}>
          <span
            className={`badge hs-form-badge print-${selectedObject[0]?.value.toLowerCase()}`}
            onBlur={blurOut}></span>
          {selectedObject[0]?.value}
        </Dropdown.Toggle>

        {/* TODO: Toggle 'show' class to hide/show menu */}
        <Dropdown.Menu
          style={{margin: 0}}
          className={`dropdown-menu hs-form-dropdown-menu ${
            toggled ? 'show' : ''
          }`}
          aria-labelledby={label}>
          {choices
            ? choices.map((choice) => {
                let tooltipMessage = 'Incompatible with ';
                if (choice.incompatible) {
                  const translation = {
                    shrinkRatio: 'ShrinkRatio',
                    finish: 'Finish',
                    diameter: 'Diameter',
                    heatshrinkColor: 'Color',
                    undefined: 'other selected options'
                  };
                  for (let i = 0; i < choice.incompatible.length - 1; i++) {
                    tooltipMessage += `${
                      translation[choice.incompatible[i]]
                    }, `;
                  }
                  tooltipMessage += `${
                    translation[
                      choice.incompatible[choice.incompatible.length - 1]
                    ]
                  }`;
                }

                const overlayChoice =
                  !choice.compatible && showIncompatible ? (
                    <Tooltip>{tooltipMessage}</Tooltip>
                  ) : (
                    <></>
                  );
                return (
                  <OverlayTrigger
                    placement="right"
                    overlay={overlayChoice}
                    key={choice.value}>
                    <Dropdown.Item
                      key={choice.value}
                      className={`dropdown-item ${
                        choice.value === selectedObject[0]?.value
                          ? 'active'
                          : ''
                      } ${!choice.compatible ? 'not-compatible' : ''}`}
                      onMouseDown={selectionClick}
                      onKeyDown={handleKeyDown}
                      title={choice.value}>
                      <span
                        className={`badge hs-form-badge print-${choice.value.toLowerCase()}`}></span>
                      {choice.value}
                    </Dropdown.Item>
                  </OverlayTrigger>
                );
              })
            : null}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default Color;
