import {useDispatch, useSelector} from 'react-redux';
import {
  applicationSelector,
  updateDesign,
  updateAppTab,
  updateRequestedAppTab,
  updateCurrentKonvaObject
} from '../../slices/application';
import Modal from './Modal';

const SaveModal = () => {
  const {currentKonvaObject, requestedAppTab, design} = useSelector(
    applicationSelector,
    (oldState, newState) => {
      return oldState.requestedAppTab === newState.requestedAppTab;
    }
  );
  const dispatch = useDispatch();

  function saveBeforeLeaving() {
    const index = design.objects.findIndex(
      (obj) => obj?.id === currentKonvaObject?.id
    );
    const objects = [...design.objects];
    if (index === -1) {
      objects.push({...currentKonvaObject});
    } else {
      objects[index] = {...currentKonvaObject};
    }
    dispatch(updateDesign({objects: objects}));
    dispatch(updateCurrentKonvaObject(null));
    dispatch(updateAppTab(requestedAppTab));
  }

  function noSaveBeforeLeaving() {
    dispatch(updateAppTab(requestedAppTab));
  }

  function onClose() {
    dispatch(updateRequestedAppTab(null));
  }

  return (
    <Modal
      closeHandler={onClose}
      show={currentKonvaObject != null && requestedAppTab != null}
      blurBackground={true}
      solidColor={true}
      align="center"
      textColor="white"
      body={<p>Want to &quot;Save&quot; before you navigate away?</p>}
      footer={
        <>
          <button
            type="button"
            className="btn btn-primary btn-sm input-radius"
            onClick={saveBeforeLeaving}>
            Yes
          </button>
          <button
            type="button"
            className="btn btn-secondary btn-sm input-radius"
            data-bs-dismiss="modal"
            onClick={noSaveBeforeLeaving}>
            No
          </button>
        </>
      }
    />
  );
};

export default SaveModal;
