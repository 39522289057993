import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Dropdown} from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux';
import './ChooseQuantity.scss';
import {
  applicationSelector,
  updatePrintTab,
  requestAppTabUpdate,
  updateDesign
} from '../../../slices/application';
import {
  getPriceForDesign,
  getPriceListForDesign,
  finalizeDesign
} from '../../../api';
import {
  convertDiameterToText,
  extractCanvasMeasurementsFromDesign
} from '../../../utils/utils';
import HspDropdown from '../../Dropdowns/HspDropdown/HspDropdown';

const ChooseQuantity = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {design} = useSelector(applicationSelector);
  const [selected, setSelected] = useState({
    quantity: 500,
    price: '$180.00',
    perPiece: '$0.36',
    savings: 50
  }); // default placeholder
  const [priceQuants, setPriceQuants] = useState([
    {quantity: 500, price: '$180.00', perPiece: '$0.36', savings: 50}
  ]); // default placeholder
  const [customQuantity, setCustomQuantity] = useState(500);
  const [orderInstructions, setOrderInstructions] = useState(
    design.orderInstructions
  );
  const [measurements, setMeasurements] = useState(
    extractCanvasMeasurementsFromDesign(design)
  );
  const conversionForText = {
    0: '',
    0.25: '¼',
    0.38: '⅜',
    0.5: '½',
    0.63: '⅝',
    0.75: '¾'
  };
  const [ref, setRef] = useState(null);
  useEffect(() => {
    if (ref != null) {
      selectQuantityBox();
    }
  }, [ref]);

  useEffect(() => {
    getPriceListForDesign(design)
      .then((response) => {
        setPriceQuants(response.data);
      })
      .catch((error) => {
        console.error('Network error:', error);
      });
  }, [design.diameter, design.length, design.shrinkRatio]);

  useEffect(() => {
    const index = priceQuants.findIndex((obj) => {
      return obj.quantity == selected.quantity;
    });
    if (index != -1) {
      setSelected(priceQuants[index]);
    }
  }, [priceQuants]);
  useEffect(() => {
    setMeasurements(extractCanvasMeasurementsFromDesign(design));
  }, [design.diameter, design.length, design.canvasHeight, design.canvasWidth]);

  useEffect(() => {
    setOrderInstructions(design.orderInstructions);
  }, [design.orderInstructions]);

  function sendCartDataToParent(data) {
    let chosenDesign;
    if (data.fail) {
      chosenDesign = data;
    }
    window.parent.postMessage(chosenDesign, '*');
  }

  function confirmCheck(data) {
    let chosenDesign;
    const lengthText = `${Math.floor(Number(design.length))}${
      conversionForText[
        Number(design.length) - Math.floor(Number(design.length))
      ]
    }"`;
    const diameterText = convertDiameterToText(Number(design.diameter));
    const description = `STANDARD HEAT SHRINK TUBING, ${design.heatshrinkColor}, ${diameterText} DIAMETER, ${lengthText} LENGTH, ${design.shrinkRatio} SHRINK RATIO, ${design.printColor} PRINT`; // eslint-disable-line max-len
    if (data.fail) {
      chosenDesign = data;
    } else {
      const d = new Date();
      chosenDesign = {
        designID: data.designId,
        product: data.partNumber,
        perPiece: selected['perPiece'],
        price: selected['price'],
        cartThumbnail: data.cartThumbnail,
        orderInstructions: orderInstructions,
        description: description,
        time: d.getTime(),
        quantity:
          selected['quantity'] == 'custom'
            ? customQuantity
            : selected['quantity']
      };
      navigate('/confirm', {state: chosenDesign});
    }
  }

  const updateOrderInstructions = async function (e) {
    const text = e.target.value;
    setOrderInstructions(text);
    dispatch(updateDesign({orderInstructions: text}));
  };

  function getCanvasImage(useFillColor) {
    const tempCanvas = document.createElement('canvas');
    const tempContext = tempCanvas.getContext('2d');
    const scale = window.devicePixelRatio;
    const {padding, inset, tubeWidth, tubeHeight, ellipseRX} = measurements;

    const colorDictionary = {
      Black: '#33333',
      Blue: '#578dbe',
      Brown: '#936b53',
      Clear: '#f9f9f9',
      Gold: '#cfaf4e',
      Gray: '#8e9089',
      Green: '#00c65e',
      Orange: '#ffa06a',
      Purple: '#8068af',
      Red: '#b41d3c',
      Silver: '#bfbfbf',
      White: '#ffffff',
      Yellow: '#f2c640'
    };

    const canvases =
      document.getElementsByClassName('konvajs-content')[0].children;
    tempCanvas.width = (tubeWidth - ellipseRX) * scale;
    tempCanvas.height = tubeHeight * scale;

    if (useFillColor) {
      const colorHex = colorDictionary[design.heatshrinkColor];
      tempContext.fillStyle = colorHex;
      tempContext.fillRect(0, 0, tempCanvas.width, tempCanvas.height);
    }

    for (const canvas of canvases) {
      const topLeftX =
        (padding + inset + (padding + inset + tubeWidth) / design.length / 16) *
        scale;
      const topLeftY = canvas.height / 2 - (tubeHeight / 2) * scale + 10;
      const bottomRightX =
        (padding +
          inset +
          tubeWidth -
          ellipseRX -
          (padding + inset + tubeWidth) / design.length / 16) *
        scale;
      const bottomRightY = canvas.height / 2 + (tubeHeight / 2) * scale - 10;

      tempContext.drawImage(
        canvas,
        topLeftX,
        topLeftY,
        bottomRightX - topLeftX,
        bottomRightY - topLeftY,
        ((padding + inset + tubeWidth) / design.length / 16) * scale,
        10,
        bottomRightX - topLeftX,
        bottomRightY - topLeftY
      );
    }

    return tempCanvas;
  }

  function addPaddingToImage(canvas, padding = 50) {
    const context = canvas.getContext('2d');

    // Save the current image data
    const imageData = context.getImageData(0, 0, canvas.width, canvas.height);

    // Calculate the maximum dimension and add padding
    const maxDimension = Math.max(canvas.width, canvas.height) + 2 * padding;
    canvas.width = maxDimension;
    canvas.height = maxDimension;

    // Fill the canvas with white color
    context.fillStyle = '#ffffff';
    context.fillRect(0, 0, canvas.width, canvas.height);

    // Put the image data back into the center of the canvas
    const offsetX = (canvas.width - imageData.width) / 2;
    const offsetY = (canvas.height - imageData.height) / 2;
    context.putImageData(imageData, offsetX, offsetY);

    return canvas;
  }

  const addToCart = async function () {
    const submitButton = document.getElementById('submitBtn');
    submitButton.setAttribute('disabled', true);
    const thumbCanvas = addPaddingToImage(getCanvasImage(true));
    const compCanvas = getCanvasImage(false);
    finalizeDesign(
      design,
      thumbCanvas.toDataURL('image/jpeg', 1.0),
      compCanvas.toDataURL()
    )
      .then((response) => confirmCheck(response.data))
      .catch((error) => {
        submitButton.setAttribute('disabled', false);
        sendCartDataToParent({error: error.message, fail: true});
      });
  };

  const previousTab = () => {
    dispatch(requestAppTabUpdate('choose-print'));
    dispatch(updatePrintTab('select-options'));
  };

  const selectQuantityBox = () => {
    ref.focus();
    setTimeout(() => {
      ref.select();
    }, 100);
  };

  function getDefaultPriceList() {
    return priceQuants.map((pricing) => {
      return (
        <Dropdown.Item
          onClick={() => setSelected(pricing)}
          key={pricing['quantity']}
          className={`custom ${
            pricing['quantity'] == selected['quantity'] ? 'active' : ''
          }`}>
          <span
            className={`hs-form-quantity ${
              pricing['quantity'] == 500 ? 'recommended' : ''
            }`}>
            <strong>{pricing['quantity']}</strong>
            {pricing['quantity'] == 500 ? <small>Recommended</small> : null}
          </span>
          <span className="hs-form-price">
            <strong>{pricing['price']}</strong>
            <small>
              <span>{pricing['perPiece']} ea.</span> /{' '}
              <span>{Math.round(pricing['savings'])}% Savings</span>
            </small>
          </span>
        </Dropdown.Item>
      );
    });
  }

  function getDropdownToggle() {
    return 'custom' == selected['quantity'] ? (
      <>
        <span
          style={{fontStyle: 'italic'}}
          className="hs-form-quantity choose-quantity">
          Choose Your Own Quantity
          <span>(min 50)</span>
        </span>
      </>
    ) : (
      <>
        <span
          className={`hs-form-quantity ${
            selected['quantity'] == 500 ? 'recommended' : ''
          }`}>
          <strong>{selected['quantity']}</strong>
          {selected['quantity'] == 500 ? <small>Recommended</small> : null}
        </span>
        <span className="hs-form-price">
          <strong>{selected['price']}</strong>
          <small>
            <span>{selected['perPiece']} ea.</span> /{' '}
            <span>{Math.round(selected['savings'])}% Savings</span>
          </small>
        </span>
      </>
    );
  }

  function getChooseQuantityItem() {
    return (
      <Dropdown.Item
        onClick={() => {
          setSelected({
            quantity: 'custom',
            price: '$180.00',
            perPiece: '$0.36',
            savings: 50
          });
          selectQuantityBox();
        }}
        key="custom"
        className={`custom ${
          'custom' == selected['quantity'] ? 'active' : ''
        }`}>
        <span style={{fontStyle: 'italic'}} className="choose-quantity">
          Choose Your Own Quantity
        </span>
      </Dropdown.Item>
    );
  }

  function updateCustomQuantity(value) {
    if (value >= 50 && value <= 99999) {
      setCustomQuantity(value);
      getPriceForDesign(design, value).then((response) => {
        setSelected({
          quantity: 'custom',
          price: response.data.price,
          perPiece: response.data.perPiece,
          savings: response.data.savings
        });
      });
    }
  }

  function verifyCustomQuantity(e) {
    if (e.target.value < 50) {
      document.getElementById('customQuantity').value = 50;
      updateCustomQuantity(50);
    }
    if (e.target.value > 99999) {
      document.getElementById('customQuantity').value = 99999;
      updateCustomQuantity(99999);
    }
  }

  function getCustomQuantityFields() {
    const customQuantDisplayStyle =
      selected['quantity'] == 'custom' ? 'inline-flex' : 'none';
    return (
      <div
        className="hs-form-control-group"
        style={{display: customQuantDisplayStyle}}>
        <span className={'hs-form-quantity'}>
          <input
            ref={setRef}
            id="customQuantity"
            type="number"
            min={50}
            max={99999}
            defaultValue={customQuantity}
            onFocus={selectQuantityBox}
            onChange={(e) => updateCustomQuantity(e.target.value)}
            onBlur={(e) => verifyCustomQuantity(e)}
          />
        </span>
        <span className="hs-form-price">
          <strong>{selected['price']}</strong>
          <small>
            <span>{selected['perPiece']} ea.</span> /{' '}
            <span>{Math.round(selected['savings'])}% Savings</span>
          </small>
        </span>
      </div>
    );
  }

  return (
    <form
      className="hs-form choose-quantity"
      onSubmit={(e) => {
        e.preventDefault();
      }}>
      <div className="hs-form-header">
        <h2 className="hs-form-heading">Choose Your Quantity</h2>
      </div>
      <div className="hs-form-container">
        <div className="hs-form-body">
          {/* TODO get prices & change the display more */}
          <HspDropdown
            topLevelClassNames="custom"
            label={{
              labelContent: (
                <>
                  Quantity * <span>Price</span>
                </>
              ),
              labelKey: 'quantity',
              labelClasses: 'quantity-price'
            }}
            dropdownToggleItems={{
              isDropdownActive: false,
              dropdownText: getDropdownToggle(),
              dropdownClasses: 'quantity-price'
            }}
            dropdownMenuItems={[
              ...getDefaultPriceList(),
              getChooseQuantityItem()
            ]}
          />
          {getCustomQuantityFields()}
          <div className="hs-form-control-group">
            <label
              htmlFor="OrderInstructions"
              className="form-label hs-form-label">
              Order Instructions
            </label>
            <textarea
              className="form-control"
              id="OrderInstructions"
              maxLength={200}
              rows="4"
              onChange={updateOrderInstructions}
              onBlur={updateOrderInstructions}
              style={{resize: 'none'}}
              value={orderInstructions ?? ''}
            />
          </div>
        </div>
        <div className="hs-form-footer">
          <button
            type="button"
            className="btn btn-outline-primary btn-sm input-radius"
            onClick={previousTab}>
            <em>Previous</em>
          </button>
          <button
            id="submitBtn"
            type="button"
            className="btn btn-success btn-sm input-radius float-end"
            onClick={addToCart}>
            Add to Cart
          </button>
        </div>
      </div>
    </form>
  );
};

export default ChooseQuantity;
