import React from 'react';
import './SelectTubeLayer.scss';
import {useSelector} from 'react-redux';
import {applicationSelector} from '../../slices/application';

const CanvasFooter = () => {
  const {appTab} = useSelector(applicationSelector);

  return (
    <div className="hs-canvas-footer">
      {/* TODO: Show this element on the "Select Your Tubing" step */}
      {/* <label className="hs-canvas-label advisory">Note: Heat Shrink Tubing Colors are not exact, and May Vary.</label> */}

      {/* TODO: Show this element on the "Select Your Quantity" step */}
      {appTab === 'choose-quantity' ? (
        <label className="hs-canvas-label disclaimer">
          By adding this order to your cart, you are approving this design as
          seen above.
        </label>
      ) : null}

      {/* TODO: Show this element on the "Select Your Tubing" step */}
      {appTab === 'choose-tubing' ? (
        <label className="hs-canvas-label advisory">
          Note: Heat Shrink Tubing Colors are not exact, and May Vary.
        </label>
      ) : null}
    </div>
  );
};

export default CanvasFooter;
