import {useDispatch, useSelector} from 'react-redux';
import {
  applicationSelector,
  updateShowDisclaimer
} from '../../slices/application';
import Modal from './Modal';
import siteHTML from '../SiteSpecific/SiteSpecific';

const DisclaimerModal = () => {
  const {showDisclaimer} = useSelector(
    applicationSelector,
    (newState, oldState) => {
      return newState.showDisclaimer === oldState.showDisclaimer;
    }
  );

  const dispatch = useDispatch();

  function closeHandler() {
    dispatch(updateShowDisclaimer(false));
  }

  return (
    <Modal
      closeHandler={closeHandler}
      show={showDisclaimer}
      blurBackground={true}
      solidColor={false}
      align="left"
      textColor="black"
      header={'Heat Shrink Printing Disclaimer'}
      width={'700px'}
      body={
        <>
          <p>
            Please be aware that Heatshrink Printing orders will be processed in
            the order in which they are received. Most of these orders require
            at least 3 to 5 days in production before shipping. However, we will
            do our best to get orders out as quickly as possible.
          </p>
          <p>
            <strong>
              Note: Selecting a faster shipping method does not speed up the
              production time - just the shipping time.
            </strong>
          </p>
          <p>{siteHTML('disclaimer')}</p>
        </>
      }
    />
  );
};

export default DisclaimerModal;
