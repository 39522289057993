const conversionForClassName = {
  0: '0-0',
  0.25: '1-4',
  0.38: '3-8',
  0.5: '1-2',
  0.63: '5-8',
  0.75: '3-4'
};
const conversionForText = {
  0: '',
  0.25: '¼',
  0.38: '⅜',
  0.5: '½',
  0.63: '⅝',
  0.75: '¾'
};
export const convertSizeToClass = (str) => {
  const num = Number(str);
  const wholeNumber = Math.floor(num).toString();
  return `${wholeNumber}-${conversionForClassName[num - Math.floor(num)]}`;
};

export const convertDiameterToText = (diameter) => {
  const numDiameter = Number(diameter);
  if (Math.floor(numDiameter) > 0) {
    return `${Math.floor(numDiameter)}${
      conversionForText[numDiameter - Math.floor(numDiameter)]
    }"`;
  } else {
    return `${conversionForText[numDiameter]}"`;
  }
};

export const extractCanvasMeasurementsFromDesign = (design) => {
  const canvasHeight = design.canvasHeight ?? 0;
  const canvasWidth = design.canvasWidth ?? 0;
  const canvasRatio = canvasWidth / canvasHeight;
  const padding = 30;
  const tubeAspectRatio = design.length / ((design.diameter * 3.14) / 2);
  let tubeWidth = 1;
  let tubeHeight = 1;

  if (tubeAspectRatio > canvasRatio) {
    tubeWidth = canvasWidth - padding * 2;
    tubeHeight = tubeWidth / tubeAspectRatio;
  } else {
    tubeHeight = canvasHeight - padding * 2;
    tubeWidth = tubeHeight * tubeAspectRatio;
  }
  let ellipseRX = tubeWidth * 0.05;
  tubeWidth = tubeWidth + ellipseRX;

  if (tubeWidth / tubeHeight > canvasRatio) {
    const adjustedWidth = canvasWidth - padding * 2;

    const scaleFactor = adjustedWidth / (tubeWidth + ellipseRX * 2);
    tubeWidth = tubeWidth * scaleFactor;
    tubeHeight = tubeHeight * scaleFactor;
    ellipseRX = ellipseRX * scaleFactor;
  }

  const inset = (canvasWidth - tubeWidth) / 2 - padding;
  const drawableWidth = tubeWidth - ellipseRX;

  return {
    canvasHeight: canvasHeight,
    canvasWidth: canvasWidth,
    padding: padding,
    tubeWidth: tubeWidth,
    tubeHeight: tubeHeight,
    inset: inset,
    tubeAspectRatio: tubeAspectRatio,
    canvasRatio: canvasRatio,
    ellipseRX: ellipseRX,
    drawableWidth: drawableWidth,
    drawableHeight: tubeHeight
  };
};
