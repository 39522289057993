/* eslint-disable max-len */
import calculateTextWidth from 'calculate-text-width';
import React, {useEffect, useState, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  applicationSelector,
  updateDesign,
  updateCurrentKonvaObject
} from '../../../slices/application';
import CancelBtn from '../../Buttons/AddEditCancel';
import RotateControl from './RotateControl';
import InvertControl from './InvertControl';
import CenterControl from './CenterControl';
import FlipControl from './FlipControl';
import HspDropdown from '../../Dropdowns/HspDropdown/HspDropdown';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import {Dropdown} from 'react-bootstrap';
import {extractCanvasMeasurementsFromDesign} from '../../../utils/utils';

const AddEditText = () => {
  const dispatch = useDispatch();
  const {design, currentKonvaObject, printTab, autoSelect} =
    useSelector(applicationSelector);
  const adding = printTab === 'add-text';
  const fonts = [
    {
      id: 'Helvetica',
      value: 'Helvetica',
      css: {fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif'}
    },
    {
      id: 'Times New Roman',
      value: 'Times New Roman',
      css: {fontFamily: '"Times New Roman", serif'}
    },
    {
      id: 'Roboto Slab',
      value: 'Roboto Slab',
      css: {fontFamily: '"Roboto Slab"'}
    },
    {
      id: 'Montserrat',
      value: 'Montserrat',
      css: {fontFamily: '"Montserrat"'}
    },
    {
      id: 'Bebas Neue',
      value: 'Bebas Neue',
      css: {fontFamily: 'Bebas Neue'}
    },
    {
      id: 'Comfortaa',
      value: 'Comfortaa',
      css: {fontFamily: '"Comfortaa"'}
    },
    {
      id: 'Alfa Slab One',
      value: 'Alfa Slab One',
      css: {fontFamily: '"Alfa Slab One"'}
    },
    {
      id: 'Pacifico',
      value: 'Pacifico',
      css: {fontFamily: '"Pacifico"'}
    },
    {
      id: 'Roboto Mono',
      value: 'Roboto Mono',
      css: {fontFamily: '"Roboto Mono"'}
    },
    {
      id: 'Courier Prime',
      value: 'Courier Prime',
      css: {fontFamily: '"Courier Prime"'}
    },
    {
      id: 'Yellowtail',
      value: 'Yellowtail',
      css: {fontFamily: '"Yellowtail"'}
    }
  ];

  const styles = [
    {
      id: 'Normal',
      value: 'Normal',
      css: {fontStyle: '', fontWeight: 400, textDecoration: ''}
    },
    {
      id: 'Bold',
      value: 'Bold',
      css: {fontStyle: 'bold', fontWeight: 700, textDecoration: ''}
    },
    {
      id: 'Italic',
      value: 'Italic',
      css: {fontStyle: 'italic', fontWeight: 400, textDecoration: ''}
    },
    {
      id: 'Underline',
      value: 'Underline',
      css: {fontStyle: '', fontWeight: 400, textDecoration: 'underline'}
    }
  ];

  const textTxt = currentKonvaObject?.text ?? '';
  const selectFont = currentKonvaObject?.fontFamily ?? 'Helvetica';
  const selectStyle = currentKonvaObject?.fontStyle ?? 'Normal';

  const {tubeWidth} = extractCanvasMeasurementsFromDesign(design);
  const [font, setFont] = useState(selectFont);
  const [text, setText] = useState(textTxt);
  const [style, setStyle] = useState(selectStyle);
  const textInput = useRef();

  useEffect(() => {
    if (autoSelect) {
      handleTextBoxFocus();
    }
  }, [autoSelect]);

  useEffect(() => {
    if (adding) {
      addText('Text');
      handleTextBoxFocus();
    }
  }, []);

  useEffect(() => {
    if (currentKonvaObject) {
      // assume currentKonva obj is text
      setText(currentKonvaObject?.text);
      setFont(currentKonvaObject?.fontFamily);
      let selectedStyle = 'Normal';
      if (currentKonvaObject?.fontStyle == 'bold') {
        selectedStyle = 'Bold';
      } else if (currentKonvaObject?.fontStyle == 'italic') {
        selectedStyle = 'Italic';
      } else if (currentKonvaObject?.textDecoration == 'underline') {
        selectedStyle = 'Underline';
      }
      setStyle(selectedStyle);
    } else {
      // also "reset" defaults
      setFont('Helvetica');
      setText('');
      setStyle('Normal');
    }
  }, [currentKonvaObject]);

  function finder(arr, id) {
    return arr.find((e) => {
      return e.id == id;
    });
  }

  function capitalize(input) {
    return input.charAt(0).toUpperCase() + input.slice(1);
  }

  function dropMenu(array, id) {
    if (id == '') {
      id = array[0].id;
    }
    const dropId = capitalize(id);
    const f = finder(array, dropId);
    return <span style={f.css}>{f.value}</span>;
  }

  function getDropdownMenuItems(type, options, func) {
    return options.map((option) => {
      return (
        <OverlayTrigger placement="right" overlay={<></>} key={option.value}>
          <Dropdown.Item
            onClick={func}
            key={option.id}
            className={`dropdown-item ${
              option.value == design[type] ? 'active' : ''
            }`}
            title={option.id}
            data-value={option.value}
            data-type={type}
            style={option.css}>
            {option.value}
          </Dropdown.Item>
        </OverlayTrigger>
      );
    });
  }

  // also add text when hit enter inside of text box
  const addTextEnter = (e) => {
    setText(e.target.value);
    addText(e.target.value);
  };

  const saveText = () => {
    const objects = [...design.objects];
    const index = objects.findIndex((obj) => obj.id === currentKonvaObject?.id);
    if (index == -1) {
      objects.push(currentKonvaObject);
      dispatch(updateDesign({objects: objects}));
    } else {
      objects[index] = currentKonvaObject;
      dispatch(updateDesign({objects: objects}));
    }
    dispatch(updateCurrentKonvaObject(null));
  };

  // will add if "new" or edit if a text selected
  const addText = (txt) => {
    let txtwidth = `${style.toLowerCase()} 500 50px ${font}`;
    // bold text is wider
    if (style == 'Bold') {
      // wrong width calculation if style is bold, just up font weight
      txtwidth = `normal 700 50px ${font}`;
    }

    dispatch(
      updateCurrentKonvaObject({
        text: txt,
        width: calculateTextWidth(txt, txtwidth),
        offsetX: Math.ceil(calculateTextWidth(txt, txtwidth) / 2),
        scaleX: calculateTextWidth(txt, txtwidth) / tubeWidth
      })
    );
  };

  // Function to set style attributes by name
  // In name of style, out object with attributes set
  function styler(name) {
    let fstyle = '';
    let tdeco = '';
    if (name == 'Bold') {
      fstyle = 'bold';
    } else if (name == 'Italic') {
      fstyle = 'italic';
    } else if (name == 'Underline') {
      tdeco = 'underline';
    } else if (name == 'Normal') {
      tdeco = 'normal';
    }
    return {style: fstyle, deco: tdeco};
  }

  // update style var on selection, also on page
  const styleSelect = (e) => {
    setStyle(e.target.title);
    // assuming it can only be a konva text object here
    let txtwidth = `${e.target.title.toLowerCase()} 500 50px ${font}`;
    // bold text is wider
    if (e.target.title == 'Bold') {
      // wrong width calculation if style is bold, just up font weight
      txtwidth = `normal 700 50px ${font}`;
    }
    const newWidth = calculateTextWidth(currentKonvaObject.text, txtwidth);
    // style not updated yet, so use the selection directly
    const fontstyle = styler(e.target.title);
    dispatch(
      updateCurrentKonvaObject({
        fontStyle: fontstyle.style,
        textDecoration: fontstyle.deco,
        width: newWidth,
        offsetX: newWidth / 2
      })
    );
  };

  const fontSelect = (e) => {
    setFont(e.target.title);
    // update size of text box when change fonts, they have different sizes
    let txtwidth = `${style} 500 50px ${e.target.title}`;
    // bold text is wider
    if (style == 'Bold') {
      // wrong width calculation if style is bold, just up font weight
      txtwidth = `normal 700 50px ${e.target.title}`;
    }
    const newWidth = calculateTextWidth(currentKonvaObject.text, txtwidth);

    // update the active text
    if (currentKonvaObject) {
      dispatch(
        updateCurrentKonvaObject({
          fontFamily: e.target.title,
          width: newWidth,
          offsetX: newWidth / 2
        })
      );
    }
  };

  const removeText = () => {
    if (currentKonvaObject) {
      const updatedObj = [];
      // clear out text box if selected
      setText('');
      design.objects.map((obj) => {
        const clone = {...obj};
        if (clone.id != currentKonvaObject.id) {
          updatedObj.push(clone);
        }
      });

      dispatch(
        updateDesign({
          objects: updatedObj
        })
      );
      dispatch(updateCurrentKonvaObject(null));
    }
  };

  const handleTextBoxFocus = () => {
    if (textInput && textInput.current) {
      textInput.current.focus();
      setTimeout(() => {
        if (textInput && textInput.current) {
          textInput.current.select();
        }
      }, 100);
    }
  };
  return (
    <div className="hs-form-container">
      <div className="hs-form-header">
        <h2 className="hs-form-heading">{adding ? 'ADD' : 'EDIT'} TEXT</h2>
      </div>
      <div className="hs-form-body">
        <div className="hs-form-control-group">
          <label htmlFor="PrintText" className="form-label hs-form-label">
            Text
          </label>
          <input
            ref={textInput}
            type="text"
            className="form-control hs-form-control"
            id="PrintText"
            name="PrintText"
            value={text}
            onChange={(e) => {
              addTextEnter(e);
            }}
            onFocus={handleTextBoxFocus}
          />
        </div>

        <HspDropdown
          label={{
            labelContent: 'Font',
            labelKey: 'font'
          }}
          topLevelClassNames={''}
          dropdownMenuItems={getDropdownMenuItems('font', fonts, fontSelect)}
          dropdownToggleItems={{
            isDropdownActive: false,
            dropdownText: dropMenu(fonts, selectFont),
            dropdownClasses: ''
          }}
        />

        <div className="hs-form-control-row size-rotate">
          <HspDropdown
            label={{
              labelContent: 'Style',
              labelKey: 'style'
            }}
            topLevelClassNames={''}
            dropdownMenuItems={getDropdownMenuItems(
              'style',
              styles,
              styleSelect
            )}
            dropdownToggleItems={{
              isDropdownActive: false,
              dropdownText: dropMenu(styles, style),
              dropdownClasses: ''
            }}
          />
          <RotateControl />
        </div>

        <div className="hs-form-control-row invert-center-flip">
          <InvertControl disabled={true} />
          <CenterControl />
          <FlipControl />
        </div>
      </div>
      <div className="hs-form-footer">
        <CancelBtn />
        <button
          type="submit"
          className="btn btn-primary btn-sm input-radius float-end"
          onClick={saveText}>
          {adding ? 'Add' : 'Save'}
        </button>
        <button
          type="button"
          className="btn btn-danger btn-sm input-radius float-end"
          id="remove"
          style={{display: printTab == 'edit-text' ? 'inline-block' : 'none'}}
          onClick={removeText}>
          Remove
        </button>
      </div>
    </div>
  );
};

export default AddEditText;
