/* eslint-disable max-len */
import {LightenDarkenColor} from 'lighten-darken-color';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import styled from 'styled-components';
import {applicationSelector} from '../../slices/application';

const TubeLayerCanvas = () => {
  const padding = 30;
  const flatten = 3.14 / 2;
  const ellipseRadiusPercent = 0.05;

  const lightenDarkenColor = LightenDarkenColor;
  const {design, appTab} = useSelector(applicationSelector);
  const [canvasWidth, setCanvasWidth] = useState(design.canvasWidth ?? 200);
  const [canvasHeight, setCanvasHeight] = useState(design.canvasHeight ?? 400);
  const [canvasRatio, setCanvasRatio] = useState(canvasWidth / canvasHeight);
  const [tubeAspectRatio, setTubeAspectRatio] = useState(
    design.length / (design.diameter * flatten)
  );
  const [tubeWidth, setTubeWidth] = useState(
    (tubeAspectRatio >= canvasRatio
      ? canvasWidth - padding * 2
      : (canvasHeight - padding * 2) * tubeAspectRatio) * 1.05
  );
  const [ellipseRX, setEllipseRX] = useState((tubeWidth / 1.05) * 0.05);
  const [tubeHeight, setTubeHeight] = useState(tubeWidth / tubeAspectRatio);
  const [inset, setInset] = useState(0);

  const colorDictionary = {
    Black: '#33333',
    Blue: '#578dbe',
    Brown: '#936b53',
    Clear: '#f9f9f9',
    Gold: '#cfaf4e',
    Gray: '#8e9089',
    Green: '#00c65e',
    Orange: '#ffa06a',
    Purple: '#8068af',
    Red: '#b41d3c',
    Silver: '#bfbfbf',
    White: '#ffffff',
    Yellow: '#f2c640'
  };

  const colorHex = colorDictionary[design.heatshrinkColor];
  const endHex =
    design.heatshrinkColor == 'Clear'
      ? '#e0e0e0'
      : colorDictionary[design.heatshrinkColor];
  const gradient = (
    <linearGradient
      id="_Linear1"
      x1="0%"
      y1="0%"
      x2="0%"
      y2="100%"
      gradientUnits="userSpaceOnUse">
      <stop offset="0%" stopColor={colorHex} />
      <stop offset="100%" stopColor={endHex} />
    </linearGradient>
  );

  async function setAttributes() {
    let drawing = document.getElementsByClassName('drawing')[0];

    // Add a counter to prevent infinite loop
    let counter = 0;
    while (
      (drawing?.clientHeight === 0 || drawing?.clientHeight == null) &&
      appTab == 'select-options' &&
      counter < 100
    ) {
      drawing = document.getElementsByClassName('drawing')[0];
      counter++;
    }

    // Check if the canvas has a non-zero width and height
    if (drawing?.clientHeight > 0 && drawing?.clientWidth > 0) {
      const cRatio = drawing.clientWidth / drawing.clientHeight;
      setCanvasRatio(cRatio);
      const tRatio = design.length / (design.diameter * flatten);
      setTubeAspectRatio(tRatio);

      let tubeW = 1;
      let tubeH = 1;
      if (tRatio > cRatio) {
        tubeW = drawing.clientWidth - padding * 2;
        tubeH = tubeW / tRatio;
      } else {
        tubeH = drawing.clientHeight - padding * 2;
        tubeW = tubeH * tRatio;
      }
      //radius of the tube hole
      let radiusX = tubeW * ellipseRadiusPercent;
      //Extend tubeWidth so post processing aspect ratio is maintained.
      tubeW += radiusX;

      if (tubeW / tubeH > cRatio) {
        const adjustedWidth = drawing.clientWidth - padding * 2;
        const scaleFactor = adjustedWidth / (tubeW + radiusX * 2);
        tubeW = tubeW * scaleFactor;
        tubeH = tubeH * scaleFactor;
        radiusX = radiusX * scaleFactor;
      }

      setCanvasHeight(drawing.clientHeight);
      setCanvasWidth(drawing.clientWidth);
      setTubeHeight(tubeH);
      setTubeWidth(tubeW);
      setEllipseRX(radiusX);

      // Inset is how far off of the left of canvas to place tube
      setInset((drawing.clientWidth - tubeW) / 2 - padding);
    } else {
      console.error(
        'Cannot set attributes with a canvas of zero width or height'
      );
    }
  }

  useEffect(() => {
    setAttributes();
  }, [design]);

  return (
    <>
      <TubeContainer className="drawing">
        <svg
          height="100%"
          width="100%"
          xmlns="http://www.w3.org/2000/svg"
          xmlSpace="preserve"
          fillRule="evenodd"
          clipRule="evenodd"
          strokeLinejoin="round"
          strokeMiterlimit="2">
          <g>
            <ellipse
              cx={padding + inset}
              cy={canvasHeight / 2}
              rx={ellipseRX}
              ry={tubeHeight / 2}
              fill="url(#_Linear1)"
              id="LeftEnd"
            />
            <g id="Tube">
              <path
                fill="url(#_Linear1)"
                d={`M${padding + inset} ${canvasHeight / 2 + tubeHeight / 2} H${
                  padding + inset + tubeWidth
                } V${canvasHeight / 2 - tubeHeight / 2} H${padding + inset} Z`}
              />
            </g>
            <ellipse
              cx={padding + inset + tubeWidth}
              cy={canvasHeight / 2}
              rx={ellipseRX}
              ry={tubeHeight / 2}
              fill={
                !['White', 'Green', 'Black'].includes(design.heatshrinkColor)
                  ? lightenDarkenColor(colorHex, -12)
                  : design.heatshrinkColor === 'White'
                    ? '#e2e2e2'
                    : design.heatshrinkColor === 'Green'
                      ? '#00a74f'
                      : '#1F1F1F'
              }
              id="RightEnd"
            />
            <ellipse
              cx={padding + inset + tubeWidth}
              cy={canvasHeight / 2}
              rx={ellipseRX * 0.8}
              ry={(tubeHeight / 2) * 0.9}
              fill={
                !['White', 'Green', 'Black'].includes(design.heatshrinkColor)
                  ? lightenDarkenColor(colorHex, -25)
                  : design.heatshrinkColor === 'White'
                    ? '#d5d5d5'
                    : design.heatshrinkColor === 'Green'
                      ? '#008941'
                      : '#000'
              }
              id="RightHole2"
            />
          </g>
          <defs>{gradient}</defs>
        </svg>
      </TubeContainer>
      <ShaderContainer className={'drawing'}>
        <svg
          height="100%"
          width="100%"
          xmlns="http://www.w3.org/2000/svg"
          xmlSpace="preserve"
          fillRule="evenodd"
          clipRule="evenodd"
          strokeLinejoin="round"
          strokeMiterlimit="2"
          className={'shade'}
          style={{opacity: appTab === 'choose-quantity' ? 1 : 0.8}}>
          <g>
            <g id="TubeBorder">
              <path
                fill="#f8f8f8"
                d={`M${padding + inset} ${canvasHeight / 2 + tubeHeight / 2}
                  H${padding + inset + tubeWidth}
                  A  ${'' /*Elliptical arc*/}
                    ${ellipseRX} ${'' /*rx*/}
                    ${tubeHeight / 2} ${'' /*ry*/}
                    0 ${'' /*rotation on x axis*/}
                    1 ${'' /*large-arc-flag*/}
                    0 ${'' /*sweep clockwise or counter clockwise*/}
                    ${padding + inset + tubeWidth} ${'' /*x coordinate*/}
                    ${canvasHeight / 2 - tubeHeight / 2} ${'' /*y coordinate*/}
                  H${padding + inset}
                  A  ${'' /*Elliptical arc*/}
                    ${ellipseRX} ${'' /*rx*/}
                    ${tubeHeight / 2} ${'' /*ry*/}
                    0 ${'' /*rotation on x axis*/}
                    1 ${'' /*large-arc-flag*/}
                    0 ${'' /*sweep-flag*/}
                    ${padding + inset} ${'' /*x coordinate*/}
                    ${canvasHeight / 2 + tubeHeight / 2} ${'' /*y coordinate*/}
                  H0
                  V0
                  H${canvasWidth}
                  V${canvasHeight}
                  H0
                  V${canvasHeight / 2 + tubeHeight / 2}
                  Z`}
              />
            </g>
            <g>
              <path
                fill="url(#_Linear1)"
                d={`M${padding + inset + tubeWidth} ${
                  canvasHeight / 2 + tubeHeight / 2
                }
                    ${'' /*Outside of tube face*/}
                    A  ${'' /*Elliptical arc*/}
                      ${ellipseRX} ${'' /*rx*/}
                      ${tubeHeight / 2} ${'' /*ry*/}
                      0 ${'' /*rotation on x axis*/}
                      1 ${'' /*large-arc-flag*/}
                      1 ${'' /*sweep clockwise or counter clockwise*/}
                      ${padding + inset + tubeWidth} ${'' /*x coordinate*/}
                      ${canvasHeight / 2 - tubeHeight / 2} ${
                        '' /*y coordinate*/
                      }
                    H ${padding + inset} ${'' /* Go to the left of tube*/}
                    A  ${'' /*Elliptical arc*/}
                      ${ellipseRX} ${'' /*rx*/}
                      ${tubeHeight / 2} ${'' /*ry*/}
                      0 ${'' /*rotation on x axis*/}
                      1 ${'' /*large-arc-flag*/}
                      0 ${'' /*sweep clockwise or counter clockwise*/}
                      ${padding + inset} ${'' /*x coordinate*/}
                      ${canvasHeight / 2 + tubeHeight / 2} ${
                        '' /*y coordinate*/
                      }
                    H ${padding + inset + tubeWidth} ${
                      '' /* Return to bottom right*/
                    }
                    ${'' /*End Outside of tube face*/}

                    ${'' /*Margin in tube face*/}
                    L ${
                      padding +
                      inset +
                      tubeWidth -
                      ellipseRX -
                      (padding + inset + tubeWidth) / design.length / 16
                    } ${canvasHeight / 2 + tubeHeight / 2 - 10}
                    L ${
                      padding +
                      inset +
                      (padding + inset + tubeWidth) / design.length / 16
                    } ${canvasHeight / 2 + tubeHeight / 2 - 10}

                    L ${
                      padding +
                      inset +
                      (padding + inset + tubeWidth) / design.length / 16
                    } ${canvasHeight / 2 - tubeHeight / 2 + 10}
                    L ${
                      padding +
                      inset +
                      tubeWidth -
                      ellipseRX -
                      (padding + inset + tubeWidth) / design.length / 16
                    } ${canvasHeight / 2 - tubeHeight / 2 + 10}

                    L ${
                      padding +
                      inset +
                      tubeWidth -
                      ellipseRX -
                      (padding + inset + tubeWidth) / design.length / 16
                    } ${canvasHeight / 2 + tubeHeight / 2 - 10}
                    Z`}
              />
              <path
                fill={
                  !['White', 'Green', 'Black'].includes(design.heatshrinkColor)
                    ? lightenDarkenColor(colorHex, -12)
                    : design.heatshrinkColor === 'White'
                      ? '#e2e2e2'
                      : design.heatshrinkColor === 'Green'
                        ? '#00a74f'
                        : '#1F1F1F'
                }
                d={`M${padding + inset + tubeWidth} ${
                  canvasHeight / 2 + tubeHeight / 2
                }
                  A  ${'' /*Elliptical arc*/}
                    ${ellipseRX} ${'' /*rx*/}
                    ${tubeHeight / 2} ${'' /*ry*/}
                    0 ${'' /*rotation on x axis*/}
                    1 ${'' /*large-arc-flag*/}
                    0 ${'' /*sweep clockwise or counter clockwise*/}
                    ${padding + inset + tubeWidth} ${'' /*x coordinate*/}
                    ${canvasHeight / 2 - tubeHeight / 2} ${'' /*y coordinate*/}

                  A  ${'' /*Elliptical arc*/}
                    ${ellipseRX} ${'' /*rx*/}
                    ${tubeHeight / 2} ${'' /*ry*/}
                    0 ${'' /*rotation on x axis*/}
                    1 ${'' /*large-arc-flag*/}
                    0 ${'' /*sweep clockwise or counter clockwise*/}
                    ${padding + inset + tubeWidth} ${'' /*x coordinate*/}
                    ${canvasHeight / 2 + tubeHeight / 2} ${'' /*y coordinate*/}
                  V ${canvasHeight / 2 + (tubeHeight / 2) * 0.9}
                  A ${'' /*eliptical arc*/}
                    ${ellipseRX * 0.8} ${'' /*rx*/}
                    ${(tubeHeight / 2) * 0.9} ${'' /*ry*/}
                    0 ${'' /*rotation on x axis*/}
                    1 ${'' /*large-arc-flag*/}
                    0 ${'' /*sweep clockwise or counter clockwise*/}
                    ${padding + inset + tubeWidth} ${'' /*x coordinate*/}
                    ${canvasHeight / 2 - (tubeHeight / 2) * 0.9} ${
                      '' /*y coordinate*/
                    }
                  A ${'' /*eliptical arc*/}
                    ${ellipseRX * 0.8} ${'' /*rx*/}
                    ${(tubeHeight / 2) * 0.9} ${'' /*ry*/}
                    0 ${'' /*rotation on x axis*/}
                    1 ${'' /*large-arc-flag*/}
                    0 ${'' /*sweep clockwise or counter clockwise*/}
                    ${padding + inset + tubeWidth} ${'' /*x coordinate*/}
                    ${canvasHeight / 2 + (tubeHeight / 2) * 0.9} ${
                      '' /*y coordinate*/
                    }
                  Z
                    `}
              />
            </g>
            <ellipse
              cx={padding + inset + tubeWidth}
              cy={canvasHeight / 2}
              rx={ellipseRX * 0.8}
              ry={(tubeHeight / 2) * 0.9}
              fill={
                !['White', 'Green', 'Black'].includes(design.heatshrinkColor)
                  ? lightenDarkenColor(colorHex, -25)
                  : design.heatshrinkColor === 'White'
                    ? '#d5d5d5'
                    : design.heatshrinkColor === 'Green'
                      ? '#008941'
                      : '#000'
              }
              id="RightHole"
            />
          </g>
          {/* <defs>{gradient}</defs> */}
        </svg>
      </ShaderContainer>
    </>
  );
};

export default TubeLayerCanvas;
const TubeContainer = styled.div`
  position: absolute;
  width: 100%;
  .tube {
    aspect-ratio: ${(props) => props.aspectRatio};
  }
`;

const ShaderContainer = styled.div`
  position: absolute;
  width: 100%;
  z-index: 100;
  pointer-events: none;
  > .shade {
    z-index: 100;
    pointer-events: none;
    left: 0;
  }
`;
