import React from 'react';

const TextBtn = ({clickHandler, text}) => {
  return (
    <button
      type="button"
      className="btn btn-outline-primary btn-sm input-radius"
      onClick={clickHandler}>
      <em>{text}</em>
    </button>
  );
};

export default TextBtn;
