import React, {useEffect, useState} from 'react';
import {Layer} from 'react-konva';
import {useDispatch, useSelector} from 'react-redux';
import {
  applicationSelector,
  updateCurrentKonvaObject
} from '../../slices/application';
import './DrawLayer.scss';
import TextElement from './TextObject';

const TextLayer = ({layerRef, measurements}) => {
  const dispatch = useDispatch();
  const {design} = useSelector(applicationSelector, (newState, oldState) => {
    const oldTextObjects = JSON.stringify(
      oldState.design.objects.filter((obj) => obj.type === 'text')
    );
    const newTextObjects = JSON.stringify(
      newState.design.objects.filter((obj) => obj.type === 'text')
    );

    return (
      oldState.design.designId === newState.design.designId &&
      oldState.design.printColor === newState.design.printColor &&
      oldTextObjects === newTextObjects
    );
  });
  const {currentKonvaObject} = useSelector(
    applicationSelector,
    (newState, oldState) => {
      if (newState.currentKonvaObject?.type == 'text')
        return (
          oldState.currentKonvaObject?.id === newState.currentKonvaObject?.id
        );
      else
        return (
          oldState.currentKonvaObject?.type ===
          newState.currentKonvaObject?.type
        );
    }
  );
  const {canvasHeight, padding, tubeWidth, tubeHeight, inset} = measurements;

  useEffect(() => {
    assembleTextSet();
  }, [currentKonvaObject, design]);
  const [objects, setObjects] = useState([]);

  function assembleTextSet() {
    const objs = design.objects.filter((obj) => {
      return obj.type === 'text';
    });
    if (currentKonvaObject?.type === 'text') {
      const index = objs.findIndex((obj) => {
        return obj.id === currentKonvaObject.id;
      });
      if (index === -1) {
        objs.push(currentKonvaObject);
      } else {
        objs[index] = currentKonvaObject;
      }
    }
    setObjects(objs);
  }

  function handleChange(object, node) {
    const width = object.textWidth ?? object.width;
    const height = object.textHeight ?? object.height;
    const currentObject = {
      ...object,
      x: (node.x() - (inset + padding)) / tubeWidth,
      y: (node.y() - (canvasHeight - tubeHeight) / 2) / tubeHeight,
      height: height,
      width: width,
      scaleX: (node.scaleX() * width) / tubeWidth,
      scaleY: (node.scaleY() * height) / tubeHeight,
      skewX: node.skewX(),
      skewY: node.skewY(),
      offsetX: node.offsetX(),
      offsetY: node.offsetY(),
      rotation: Math.round(node.rotation())
    };
    dispatch(updateCurrentKonvaObject(currentObject));
  }

  function getTextElements() {
    return objects.map((text) => (
      <TextElement
        key={text.id}
        textObject={text}
        isSelected={currentKonvaObject && text.id === currentKonvaObject.id}
        opacity={1}
        align={'center'}
        measurements={measurements}
        handleChange={handleChange}
      />
    ));
  }

  return <Layer ref={layerRef}>{getTextElements()}</Layer>;
};

export default TextLayer;
