/* eslint-disable max-len */
import React from 'react';
import {
  applicationSelector,
  updateCurrentKonvaObject
} from '../../../slices/application';
import {useDispatch, useSelector} from 'react-redux';

const FlipControl = () => {
  const dispatch = useDispatch();
  const {currentKonvaObject} = useSelector(applicationSelector);

  const flipX = () => {
    dispatch(
      updateCurrentKonvaObject({
        scaleX: -currentKonvaObject.scaleX,
        rotation: -currentKonvaObject.rotation
      })
    );
  };

  const flipY = () => {
    dispatch(
      updateCurrentKonvaObject({
        scaleY: -currentKonvaObject.scaleY,
        rotation: -currentKonvaObject.rotation
      })
    );
  };

  return (
    <div className="hs-form-control-group">
      <label htmlFor="PrintFlip" className="form-label hs-form-label">
        Flip
      </label>
      <div
        className="btn-group hs-button-group"
        role="group"
        aria-label="print flip">
        <button
          type="button"
          aria-label="flip horizontally"
          className="btn uix-btn-outline-default btn-sm input-radius active"
          id="PrintFlipHorizontal"
          onClick={flipX}>
          <svg
            className="hs-svg-flip-horizontal"
            enableBackground="new 0 0 512 512"
            version="1.1"
            viewBox="0 0 512 512"
            xmlSpace="preserve"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <path
              className="hs-svg-inherit"
              d="M466.5,182.9l-68.7,64.9l68.7,64.9V182.9 M506,133.9v227.7c0,15.8-20.1,23.6-31.9,12.5L353.6,260.3 c-7.3-6.9-7.3-18.1,0-25l120.5-113.8C485.9,110.3,506,118.2,506,133.9z"
            />
            <path
              className="hs-svg-inherit"
              d="M280.1,150.1c0,5.5-4.5,10-10,10h-28.2c-5.5,0-10-4.5-10-10V40.7c0-5.5,4.5-10,10-10h28.2c5.5,0,10,4.5,10,10 V150.1z M280.1,205.2c0-5.5-4.5-10-10-10h-28.2c-5.5,0-10,4.5-10,10v109.4c0,5.5,4.5,10,10,10h28.2c5.5,0,10-4.5,10-10V205.2z M280.1,363.1c0-5.5-4.5-10-10-10h-28.2c-5.5,0-10,4.5-10,10v109.4c0,5.5,4.5,10,10,10h28.2c5.5,0,10-4.5,10-10V363.1z"
            />
            <path
              className="hs-svg-inherit"
              d="M6,361.7V133.9c0.6-10.4,9.5-18.3,19.9-17.8c4.5,0.3,8.8,2.1,12,5.3l120.5,113.8c6.9,6.4,7.3,17.2,1,24.1 c-0.3,0.3-0.6,0.6-1,1L37.9,374.2c-7.5,7.2-19.4,7-26.6-0.4C8.1,370.5,6.3,366.2,6,361.7z"
            />
          </svg>
        </button>
        <button
          type="button"
          aria-label="flip vertically"
          className="btn uix-btn-outline-default btn-sm input-radius active"
          id="PrintFlipVertical"
          onClick={flipY}>
          <svg
            className="hs-svg-flip-vertical"
            enableBackground="new 0 0 512 512"
            version="1.1"
            viewBox="0 0 512 512"
            xmlSpace="preserve"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <path
              className="hs-svg-inherit"
              d="M329.7,467.1l-64.9-68.7l-64.9,68.7H329.7 M378.6,506.6H150.9c-15.8,0-23.6-20.1-12.5-31.9l113.8-120.5 c6.9-7.3,18.1-7.3,25,0l113.8,120.5C402.3,486.5,394.4,506.6,378.6,506.6z"
            />
            <path
              className="hs-svg-inherit"
              d="M362.5,280.7c-5.5,0-10-4.5-10-10v-28.2c0-5.5,4.5-10,10-10h109.4c5.5,0,10,4.5,10,10v28.2c0,5.5-4.5,10-10,10 H362.5z M307.4,280.7c5.5,0,10-4.5,10-10v-28.2c0-5.5-4.5-10-10-10H198c-5.5,0-10,4.5-10,10v28.2c0,5.5,4.5,10,10,10H307.4z M149.5,280.7c5.5,0,10-4.5,10-10v-28.2c0-5.5-4.5-10-10-10H40.1c-5.5,0-10,4.5-10,10v28.2c0,5.5,4.5,10,10,10H149.5z"
            />
            <path
              className="hs-svg-inherit"
              d="M150.9,6.6h227.7c10.4,0.6,18.3,9.5,17.8,19.9c-0.3,4.5-2.1,8.8-5.3,12L277.3,159c-6.4,6.9-17.2,7.3-24.1,1 c-0.3-0.3-0.6-0.6-1-1L138.4,38.5c-7.2-7.5-7-19.4,0.4-26.6C142.1,8.7,146.4,6.8,150.9,6.6z"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};
export default FlipControl;
