/* eslint-disable max-len */
import React from 'react';
import {useDispatch} from 'react-redux';
import TextBtn from './TextBtn';
import {
  updatePrintTab,
  updateCurrentKonvaObject
} from '../../slices/application';

// Cancel button on add/edit page takes back to "main" design tab and deselects object
const CancelBtn = () => {
  const dispatch = useDispatch();

  function cancel() {
    dispatch(updateCurrentKonvaObject(null));
    dispatch(updatePrintTab('select-options'));
  }

  return <TextBtn clickHandler={cancel} text="Cancel" />;
};

export default CancelBtn;
