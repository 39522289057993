import React from 'react';
import './Tooltip.scss';

const Tooltip = ({message, show}) => {
  return (
    <div
      className={`tooltip hs-tooltip bs-tooltip-right ${show ? 'show' : ''}`}
      role="tooltip">
      {/* TODO: Add 'show' class to tooltip to display tooltip */}
      {/* TODO: Add 'bs-tooltip-top', 'bs-tooltip-right', 'bs-tooltip-bottom', or 'bs-tooltip-left' class to display appropriate arrow */}
      {/* TODO: Need to add script to properly position tooltip neear the related element in the proper position */}

      <div className="tooltip-arrow hs-tooltip-arrow"></div>
      <div className="tooltip-inner hs-tooltip-inner">
        {/* TODO: Message should read "Incompatible with [Reason]" for 'not-compatible' menu items */}
        {message}
      </div>
    </div>
  );
};

export default Tooltip;
