/* eslint-disable max-len */
import React from 'react';
import {
  updateCurrentKonvaObject,
  applicationSelector
} from '../../../slices/application';
import {useDispatch, useSelector} from 'react-redux';
import {extractCanvasMeasurementsFromDesign} from '../../../utils/utils';

const CenterControl = () => {
  const dispatch = useDispatch();
  const {design} = useSelector(applicationSelector);
  const {tubeWidth, ellipseRX} = extractCanvasMeasurementsFromDesign(design);

  const centerX = () => {
    dispatch(
      updateCurrentKonvaObject({x: ((tubeWidth - ellipseRX) * 0.5) / tubeWidth})
    );
  };

  const centerY = () => {
    dispatch(updateCurrentKonvaObject({y: 0.5}));
  };

  return (
    <div className="hs-form-control-group">
      <label htmlFor="PrintCenter" className="form-label hs-form-label">
        Center
      </label>
      <div
        className="btn-group hs-button-group"
        role="group"
        aria-label="print center">
        <button
          type="button"
          aria-label="center horizontally"
          className="btn uix-btn-outline-default btn-sm input-radius"
          id="PrintCenterHorizontal"
          onClick={centerX}>
          <svg
            className="hs-svg-center-horizontal"
            enableBackground="new 0 0 512 512"
            version="1.1"
            viewBox="0 0 512 512"
            xmlSpace="preserve"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <path
              className="hs-svg-inherit"
              d="M222.7,472.9V39.6c0-15,12.1-27.1,27.1-27.1c15,0,27.1,12.1,27.1,27.1v433.3 c0,15-12.1,27.1-27.1,27.1C234.8,500,222.7,487.9,222.7,472.9z M452.1,385.8l-115-115c-7.9-7.9-8-20.7-0.1-28.7c0,0,0.1,0,0.1-0.1 l115-115.2c8-7.9,20.8-7.9,28.8,0l19.1,19.2c7.9,7.9,7.9,20.7,0,28.6l-81.5,81.6l81.6,81.6c7.9,7.9,7.9,20.7,0.1,28.6 c0,0-0.1,0-0.1,0.1l-19.2,19.2c-7.9,7.9-20.7,7.9-28.6,0.1c0,0-0.1,0-0.1-0.1L452.1,385.8z M31.1,385.8L12,366.7 c-7.9-7.8-8-20.6-0.2-28.5c0.1-0.1,0.2-0.1,0.2-0.2l81.5-81.5L12,174.8c-7.9-7.7-8-20.4-0.3-28.3c0.1-0.1,0.2-0.2,0.3-0.3L31,126.8 c7.9-7.9,20.7-7.9,28.6,0l0,0L174.8,242c7.9,7.8,8.1,20.6,0.3,28.5c-0.1,0.1-0.2,0.2-0.3,0.3l-115.1,115c-7.9,7.9-20.6,7.9-28.5,0.1 C31.1,385.9,31.1,385.9,31.1,385.8L31.1,385.8z"
            />
          </svg>
        </button>
        <button
          type="button"
          aria-label="center vertically"
          className="btn uix-btn-outline-default btn-sm input-radius"
          id="PrintCenterVertical"
          onClick={centerY}>
          <svg
            className="hs-svg-center-vertical"
            enableBackground="new 0 0 512 512"
            version="1.1"
            viewBox="0 0 512 512"
            xmlSpace="preserve"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <path
              className="hs-svg-inherit"
              d="M39.3,223h433.4c15,0,27.1,12.1,27.1,27.1s-12.1,27.1-27.1,27.1H39.3c-15,0-27.1-12.1-27.1-27.1 S24.3,223,39.3,223z M126.4,452.5l115.2-115.2c7.8-7.9,20.6-8,28.5-0.2c0.1,0.1,0.1,0.1,0.2,0.2l115.2,115.1 c7.9,7.9,7.9,20.7,0,28.7l-19.2,19.2c-7.9,7.9-20.8,7.9-28.7,0L256,418.8l-81.7,81.6c-7.9,7.9-20.7,7.9-28.6,0l-19.3-19.2 C118.5,473.2,118.5,460.4,126.4,452.5z M126.4,31.3l19.2-19.1c7.8-7.9,20.6-8,28.5-0.2c0.1,0.1,0.1,0.1,0.2,0.2l81.6,81.6l81.6-81.6 c7.8-7.9,20.6-8,28.5-0.2c0.1,0.1,0.1,0.1,0.2,0.2l19.3,19.1c7.9,7.9,7.9,20.7,0,28.6L270.3,175.1c-7.9,7.9-20.8,7.9-28.7,0l0,0 L126.4,59.9c-7.9-7.9-7.9-20.7,0-28.7V31.3z"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default CenterControl;
