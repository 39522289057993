/* eslint-disable max-len */
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import {useSelector} from 'react-redux';
import {applicationSelector} from '../../slices/application';
import {convertSizeToClass} from '../../utils/utils';
import './SelectTubeLayer.scss';

const SelectTubeLayer = () => {
  const {design, heatshrinkCompatibility} = useSelector(applicationSelector);
  const {diameter, length, heatshrinkColor, shrinkRatio} = design;
  const lengthClass = `length-${convertSizeToClass(length)}`;
  const diameterClass = `diameter-${convertSizeToClass(Number(diameter))}`;
  const tubeColorClass = heatshrinkColor?.toLowerCase();
  const shrinkRatioClass = `shrink-ratio-${shrinkRatio?.replace(':', '-')}`;
  return (
    <div
      className={`hs-canvas-body ${
        heatshrinkCompatibility.heatshrinkSelectionIsCompatible
          ? ''
          : 'invalid-tubing'
      }`}>
      <div
        className={`hs-canvas-tubing-container Standard ${diameterClass} ${lengthClass} ${shrinkRatioClass} ${tubeColorClass}`}>
        {/* RULERS */}

        <div className="hs-canvas-ruler diameter">
          <div className="hs-canvas-ruler-markers">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>

          <div className="hs-canvas-ruler-setting">
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>Diameter</Tooltip>}
              key="ruler-d">
              <label className="hs-canvas-ruler-label">(D)</label>
            </OverlayTrigger>
            <div className="hs-canvas-ruler-marker top"></div>
            <div className="hs-canvas-ruler-marker bottom"></div>
          </div>
        </div>

        <div className="hs-canvas-ruler length">
          <div className="hs-canvas-ruler-markers">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div className="hs-canvas-ruler-setting">
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>Length</Tooltip>}
              key="ruler-l">
              <label className="hs-canvas-ruler-label">(L)</label>
            </OverlayTrigger>
            <div className="hs-canvas-ruler-marker left"></div>
            <div className="hs-canvas-ruler-marker right"></div>
          </div>
        </div>

        <div className="hs-canvas-ruler shrink-ratio">
          <div className="hs-canvas-ruler-markers">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div className="hs-canvas-ruler-setting">
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>Shrink Ratio</Tooltip>}
              key="ruler-sr">
              <label className="hs-canvas-ruler-label">(R)</label>
            </OverlayTrigger>
            <div className="hs-canvas-ruler-marker top"></div>
            <div className="hs-canvas-ruler-marker bottom"></div>
          </div>
        </div>
        {/* TUBING */}
        <div className="hs-canvas-tubing">
          {/* TODO: Show these elements to display unshrunk tube */}
          {/* <svg className="hs-svg-tubing left" enableBackground="new 0 0 30 126" version="1.1" viewBox="0 0 30 126" preserveAspectRatio="none" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                          <linearGradient x1="0" y1="0" x2="0" y2="100%" id="LeftGradient">
                              <stop offset="0" className="hs-svg-gradient-0" />
                              <stop offset=".33" className="hs-svg-gradient-33" />
                              <stop offset="1" className="hs-svg-gradient-100" />
                          </linearGradient>
                          <ellipse className="hs-svg-inherit" cx="15" cy="63" rx="15" ry="63" />
                      </svg>
                      <div className="hs-tubing"></div>
                      <svg className="hs-svg-tubing right" enableBackground="new 0 0 30 126" version="1.1" viewBox="0 0 30 126" preserveAspectRatio="none" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                          <ellipse className="hs-svg-inherit" cx="15" cy="63" rx="15" ry="63" />
                      </svg>
                      <svg className="hs-svg-tubing right-hole" enableBackground="new 0 0 30 126" version="1.1" viewBox="0 0 30 126" preserveAspectRatio="none" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                          <ellipse className="hs-svg-inherit" cx="15" cy="63" rx="15" ry="63" />
                      </svg> */}

          {/* TODO: Show these elements to display shrunk tube */}
          <svg
            className="hs-svg-tubing left"
            enableBackground="new 0 0 30 126"
            version="1.1"
            viewBox="0 0 30 126"
            preserveAspectRatio="none"
            xmlSpace="preserve"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <linearGradient x1="0" y1="0" x2="0" y2="100%" id="LeftGradient">
              <stop offset="0" className="hs-svg-gradient-0" />
              <stop offset=".33" className="hs-svg-gradient-33" />
              <stop offset="1" className="hs-svg-gradient-100" />
            </linearGradient>
            <ellipse
              className="hs-svg-inherit"
              cx="15"
              cy="63"
              rx="15"
              ry="63"
            />
          </svg>
          <div className="hs-tubing left"></div>
          <svg
            className="hs-svg-tubing center"
            enableBackground="new 0 0 30 126"
            version="1.1"
            viewBox="0 0 30 126"
            preserveAspectRatio="none"
            xmlSpace="preserve"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <ellipse
              className="hs-svg-inherit"
              cx="15"
              cy="63"
              rx="15"
              ry="63"
            />
          </svg>
          <svg
            className="hs-svg-tubing shrink-left"
            enableBackground="new 0 0 30 126"
            version="1.1"
            viewBox="0 0 30 126"
            preserveAspectRatio="none"
            xmlSpace="preserve"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <linearGradient
              x1="0"
              y1="0"
              x2="0"
              y2="100%"
              id="ShrinkLeftGradient">
              <stop offset="0" className="hs-svg-gradient-0" />
              <stop offset=".33" className="hs-svg-gradient-33" />
              <stop offset="1" className="hs-svg-gradient-100" />
            </linearGradient>
            <ellipse
              className="hs-svg-inherit"
              cx="15"
              cy="63"
              rx="15"
              ry="63"
            />
          </svg>
          <div className="hs-tubing right"></div>
          <svg
            className="hs-svg-tubing shrink-right"
            enableBackground="new 0 0 30 126"
            version="1.1"
            viewBox="0 0 30 126"
            preserveAspectRatio="none"
            xmlSpace="preserve"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <ellipse
              className="hs-svg-inherit"
              cx="15"
              cy="63"
              rx="15"
              ry="63"
            />
          </svg>
          <svg
            className="hs-svg-tubing shrink-right-hole"
            enableBackground="new 0 0 30 126"
            version="1.1"
            viewBox="0 0 30 126"
            preserveAspectRatio="none"
            xmlSpace="preserve"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <ellipse
              className="hs-svg-inherit"
              cx="15"
              cy="63"
              rx="15"
              ry="63"
            />
          </svg>
        </div>

        {/* INVALID TUBING */}
        <div className="hs-canvas-tubing-invalid">
          <FontAwesomeIcon icon={['fas', 'ban']} />
          <strong>Invalid Tubing Selection</strong>
        </div>
      </div>
    </div>
  );
};

export default SelectTubeLayer;
