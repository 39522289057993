import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {configureStore} from '@reduxjs/toolkit';
import {Provider} from 'react-redux';

import * as serviceWorker from './serviceWorker';
import rootReducer from './slices';

import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: 'https://a42d53fc486bb691dc9acb2b880a3154@o4505195236098048.ingest.sentry.io/4506542301642752',
  environment: process.env.NODE_ENV,
  integrations: [
    new Sentry.ReplayCanvas(),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false
    }),
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/]
    })
  ],
  // Performance Monitoring
  tracesSampleRate: 0.05, //  Capture 5% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({serializableCheck: false})
});

const root = document.getElementById('root');

if (root !== null) {
  ReactDOM.createRoot(root).render(
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
