import React from 'react';
import {Dropdown} from 'react-bootstrap';
import '../../Tooltip/Tooltip.scss';

const HspDropdown = ({
  label,
  dropdownMenuItems,
  dropdownToggleItems,
  topLevelClassNames = '',
  includeSwatch = false
}) => {
  const {isDropdownActive, dropdownClasses, dropdownText} = dropdownToggleItems;

  return (
    <div className={`hs-form-control-group ${topLevelClassNames}`}>
      <label
        htmlFor={label.labelKey}
        className={`form-label hs-form-label ${label.labelClasses ?? ''}`}>
        {label.labelContent}
        <strong>Incompatible</strong>
      </label>
      <Dropdown className={'dropdown hs-form-dropdown'} data-testid="dropdown">
        <Dropdown.Toggle
          data-testid="dropdown-toggle"
          className={`btn uix-btn-outline-default btn-sm input-radius dropdown-toggle hs-form-dropdown-toggle
                  ${dropdownClasses ?? ''}`}
          title={label.labelKey}>
          {includeSwatch ? (
            <span
              className={`badge hs-form-badge bg-${dropdownText.toLowerCase()}`}></span>
          ) : (
            ''
          )}
          {dropdownText}
        </Dropdown.Toggle>
        <Dropdown.Menu
          data-testid="dropdown-menu"
          style={{margin: 0}}
          className={`dropdown-menu hs-form-dropdown-menu ${
            isDropdownActive ? 'show' : ''
          }`}
          aria-labelledby={label.labelKey}>
          {dropdownMenuItems.map((item) => {
            return item;
          })}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default HspDropdown;
