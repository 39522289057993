import {format} from 'date-fns';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import appConfig from '../../../config';
import {
  applicationSelector,
  updateDesign,
  updateAppTab
} from '../../../slices/application';
import {getOrders, loadDesign} from '../../../api.js';
import './Reorder.scss';

const cdnUri = appConfig.cdnUri;

const Reorder = () => {
  const [toggled, setToggled] = useState(false);
  const {uuid, appTab} = useSelector(applicationSelector);
  const dispatch = useDispatch();

  const [previousOrders, setPreviousOrders] = useState([]);

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (previousOrders.length > 0) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, [previousOrders]);

  function blurOut() {
    setToggled(false);
  }

  function handleToggle() {
    setToggled(!toggled);
  }

  async function fetchOrders() {
    try {
      const orders = await getOrders(uuid);
      setPreviousOrders(orders.data);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (uuid != null && uuid != '') {
      fetchOrders();
    }
  }, [uuid]);

  // load design from dynamo based on id
  // make sure "target" has the data attribute
  async function fetchDesign(e) {
    try {
      const response = await loadDesign(e.target.dataset.designid);
      if (!response.status === 200) {
        throw new Error(response);
      } else {
        const design = response.data;
        if (design.designId == null) {
          console.log('ERROR no design ID returned');
        } else {
          dispatch(updateDesign({...design}));
          localStorage.setItem('lastDesign', design.designId);
          if (appTab != 'choose-quantity') {
            dispatch(updateAppTab('choose-quantity'));
          } else {
            dispatch(updateAppTab('choose-print'));
            dispatch(updateAppTab('choose-quantity'));
          }
        }
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log('Error', error.message);
      }
      console.log(error.config);
    }
  }

  return (
    <div
      className={`hs-dropdown hs-form-dropdown hs-reorder-dropdown ${
        isVisible ? 'show' : ''
      }`}>
      <button
        type="button"
        id="ReorderToggle"
        className={`btn btn-secondary btn-sm input-radius dropdown-toggle hs-dropdown-toggle ${
          toggled ? 'show' : ''
        }`}
        onClick={handleToggle}
        onBlur={blurOut}>
        <svg
          className="hs-svg-reorder"
          enableBackground="new 0 0 512 512"
          version="1.1"
          viewBox="0 0 512 512"
          xmlSpace="preserve"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink">
          <path
            className="hs-svg-inherit"
            d="M92.1,444.3c12.3-12.3,24.5-24.5,36.7-36.7c51,40.6,108.8,55.2,172.7,40.4c51.9-12,92.5-41.4,121.1-86.3 c57.8-90.6,32.3-210.9-57.5-270.4c-84.6-56.1-192.5-38.4-257.3,33c1,0.7,2.2,1.2,3,2c17.8,17.8,35.7,35.6,53.4,53.5 c1.6,1.6,3,3.8,3.4,5.9c0.9,5-2.1,9-7.2,10c-1.5,0.3-3,0.3-4.6,0.3c-46.1,0-92.2,0-138.3,0C9.2,196,6,192.8,6,184.4 C6,138,6,91.6,6,45.2c0-1.5,0.1-3.1,0.4-4.5c1.5-6.2,8.8-8.7,13.8-4.8c1,0.7,1.8,1.6,2.7,2.5c15.3,15.3,30.6,30.6,45.9,45.9 c0.8,0.8,1.6,1.5,2.6,2.5c59-60.3,130.2-88.3,214.4-78.6c68.2,7.9,124,39.7,166.7,93.5c82.7,104.5,68,256.1-33.1,343.4 C320.3,530.5,178.5,521.7,92.1,444.3z"
          />
          <path
            className="hs-svg-inherit"
            d="M279.4,362.8c0,8.3,0.1,16.4,0,24.4c0,2.2-0.3,4.5-1,6.5c-1.2,3.1-3.9,4.8-7.2,4.9c-6.9,0.2-13.9,0.3-20.8,0 c-4.9-0.2-8.1-3.6-8.3-8.5c-0.2-8.1-0.2-16.1-0.1-24.2c0-2.4-0.6-3.3-3.2-3.4c-17.1-0.5-32.5-6-46.4-15.9 c-6.5-4.6-6.9-10.3-1.1-15.8c6.2-5.9,12.5-11.7,18.8-17.6c4.1-3.8,7.8-4.2,12.8-1.3c6.1,3.6,12.6,5.5,19.8,5.4 c12.9-0.1,25.9,0,38.9,0c8.8,0,15.3-5.7,16.6-14.4c1.2-7.9-3.9-15.7-12-18c-13.4-3.8-26.8-7.5-40.2-11.3 c-7.8-2.2-15.7-4.2-23.4-6.7c-25.3-8.3-41.1-25.6-45.4-51.8c-2.9-17.2,2.6-33.2,13.8-46.7c12.4-14.9,28.6-22.2,47.9-22.6 c2.6,0,3.3-0.8,3.2-3.3c-0.1-7.2-0.1-14.5,0-21.7c0.1-8,3.2-11.1,11.1-11.1c5.5,0,11-0.1,16.5,0c6.2,0.1,9.6,3.6,9.7,9.8 c0.1,7.7,0.1,15.5,0,23.2c0,2.4,0.7,3.1,3.1,3.2c17.3,0.6,32.9,6.1,46.9,16.2c2.9,2.1,5.1,4.7,4.4,8.4c-0.4,2.1-1.2,4.5-2.7,5.9 c-6.6,6.6-13.5,13-20.4,19.4c-3.4,3.1-7.3,3.5-11.3,1.1c-7.1-4.4-14.7-6.1-23-5.9c-11.8,0.2-23.7,0-35.5,0.1 c-9.6,0-16.4,5.9-17.7,15.2c-1,7.6,4.4,15,13.1,17.5c21.4,6.1,42.9,12,64.2,18.2c31.4,9.2,50.4,40.5,42.8,72.2 c-6,24.9-23.1,39.7-47.5,46.5c-2.6,0.7-5.4,1-8.1,1.4C285.1,362.4,282.5,362.5,279.4,362.8z"
          />
        </svg>
        Reorder A Design
      </button>

      {/* reorder will not be a part of iFrame in final version */}
      {/* assume the site save the designIds linked to user? how about design params */}
      <ul
        className={`dropdown-menu hs-form-dropdown-menu hs-dropdown-menu ${
          toggled ? 'show' : ''
        }`}
        aria-labelledby="ReorderToggle">
        {/* setting a few Dynamo saved designs */}
        {/* TODO: Add Tubing Image */}
        {previousOrders
          ? previousOrders.map((order) => {
              const thumbUrl = `${cdnUri}/${order.cartThumbnail}`;
              return (
                <li key={order.designId}>
                  <button
                    type="button"
                    className="dropdown-item hs-dropdown-item"
                    data-designid={order.designId}
                    onMouseDown={fetchDesign}>
                    <div className="dropdown-image">
                      <img src={thumbUrl} alt="print-image" />
                    </div>
                    <div className="dropdown-text">
                      <strong data-designid={order.designId}>
                        Last Ordered{' '}
                        {format(
                          Date.parse(order.lastTimeOrdered),
                          'EEE, MMM. dd, yyyy'
                        )}
                      </strong>
                      <span data-designid={order.designId}>
                        {`${order.heatshrinkColor}, ${order.diameter}" Diameter, ${order.length}" Length,
                  ${order.shrinkRatio} Shrink Ratio w/ ${order.printColor} Print`}
                      </span>
                    </div>
                  </button>
                </li>
              );
            })
          : null}
      </ul>
    </div>
  );
};

export default Reorder;
