/* eslint-disable max-len */
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  applicationSelector,
  updateCurrentKonvaObject,
  updateDesign,
  updatePrintTab
} from '../../../slices/application';
import CancelBtn from '../../Buttons/AddEditCancel';
import RotateControl from './RotateControl';
import InvertControl from './InvertControl';
import CenterControl from './CenterControl';
import FlipControl from './FlipControl';

const AddEditArt = () => {
  const dispatch = useDispatch();
  const {design, currentKonvaObject, printTab} =
    useSelector(applicationSelector);

  const [selectImg, setSelectImg] = useState(<p>Please choose an image</p>);
  const [threshold, setThreshold] = useState(0.95);

  // adding "defaults" for new obj vs if selected thing.
  const formState = printTab == 'add-art' ? 'add' : 'edit';

  // when selected obj change, change the image if necessary.
  useEffect(() => {
    if (currentKonvaObject?.id == null) {
      dispatch(updatePrintTab('select-options'));
    }

    // when click on existing image, the "Artwork" change to match
    // otherwise, if a new artwork set, use that as addable src
    if (currentKonvaObject?.type == 'image') {
      setThreshold(currentKonvaObject.threshold);
      setSelectImg(imageSelected(currentKonvaObject));
    }
  }, [currentKonvaObject?.id]);

  // this should be changed when "change image" is changed
  function imageSelected(data) {
    return (
      <img
        className="hs-form-file art-show"
        id="SelectedArt"
        alt="selected art"
        data-width={data.width}
        data-height={data.height}
        src={data.url}
      />
    );
  }

  const changeThreshold = (e) => {
    let input = Number(e);
    if (Number.isNaN(input)) input = 0.95;
    setThreshold(input);
    dispatch(updateCurrentKonvaObject({threshold: input}));
  };

  const saveImage = () => {
    const objects = [...design.objects];
    const index = objects.findIndex((obj) => obj.id === currentKonvaObject?.id);
    if (index == -1) {
      objects.push(currentKonvaObject);
      dispatch(updateDesign({objects: objects}));
    } else {
      objects[index] = currentKonvaObject;
      dispatch(updateDesign({objects: objects}));
    }
    dispatch(updateCurrentKonvaObject(null));
  };

  const removeArt = () => {
    const updatedObj = [];
    design.objects.map((obj) => {
      if (obj.id != currentKonvaObject?.id) {
        updatedObj.push({...obj});
      }
    });
    // also save removing art object to db
    dispatch(
      updateDesign({
        objects: updatedObj
      })
    );
    dispatch(updateCurrentKonvaObject(null));
  };

  function handleChangeImage() {
    dispatch(updatePrintTab('select-art'));
  }

  return (
    <div className="hs-form-container">
      <div className="hs-form-header">
        <h2 className="hs-form-heading">{formState} IMAGE</h2>
      </div>
      <div className="hs-form-body">
        <div className="hs-form-control-group file">
          <label htmlFor="PrintImage" className="form-label hs-form-label">
            Artwork
          </label>
          <div className="hs-form-wrapper">
            <button
              type="button"
              className="btn uix-btn-outline-link btn-sm hs-form-file-link"
              onClick={() => handleChangeImage()}>
              Change Image
            </button>
            {selectImg}
          </div>
        </div>

        <div className="hs-form-control-row size-rotate">
          <div className="hs-form-control-group">
            <label
              htmlFor="PrintThreshold"
              className="form-label hs-form-label">
              Threshold
            </label>
            <div className="hs-form-wrapper">
              <input
                type="range"
                min="0.2"
                max="1.0"
                step="0.01"
                value={threshold}
                onChange={(e) => changeThreshold(e.target.value)}
                id="PrintThreshold"
                name="PrintThreshold"
                className="hs-form-control slider"></input>
            </div>
          </div>
          <RotateControl />
        </div>
        <div className="hs-form-control-row invert-center-flip">
          <InvertControl disabled={false} />
          <CenterControl />
          <FlipControl />
        </div>
      </div>
      <div className="hs-form-footer">
        <CancelBtn />
        <button
          type="submit"
          className="btn btn-primary btn-sm input-radius float-end"
          onClick={saveImage}>
          Save
        </button>
        <button
          type="button"
          className="btn btn-danger btn-sm input-radius float-end"
          id="remove"
          style={{display: formState == 'edit' ? 'inline-block' : 'none'}}
          onClick={removeArt}>
          Remove
        </button>
      </div>
    </div>
  );
};

export default AddEditArt;
