let serverUri, cdnUri;

switch (process.env.REACT_APP_ENVIRONMENT) {
  case 'production':
    serverUri = 'https://api.heatshrink.guru';
    cdnUri = 'https://cdn.heatshrink.guru';
    break;
  case 'development':
    serverUri =
      'https://' +
      process.env.REACT_APP_JIRA_ISSUE_KEY +
      '-api.heatshrink.guru';
    cdnUri = 'https://dev-cdn.heatshrink.guru';
    break;
  case 'local':
    serverUri = 'http://localhost:8080';
    cdnUri = 'https://dev-cdn.heatshrink.guru';
}

const config = {
  jiraIssueKey: process.env.REACT_APP_JIRA_ISSUE_KEY,
  serverUri: serverUri,
  cdnUri: cdnUri
};

export default config;
