import React, {useEffect, useState} from 'react';
import {Layer} from 'react-konva';
import {useDispatch, useSelector} from 'react-redux';
import {
  applicationSelector,
  updateCurrentKonvaObject
} from '../../slices/application';
import './DrawLayer.scss';
import ImageElement from './ImageObject';

const ImageLayer = ({layerRef, measurements}) => {
  const dispatch = useDispatch();
  const {design} = useSelector(applicationSelector, (newState, oldState) => {
    const oldImageObjects = JSON.stringify(
      oldState.design.objects
        .filter((obj) => obj.type === 'image')
        .sort((a, b) => a.id - b.id)
    );
    const newImageObjects = JSON.stringify(
      newState.design.objects
        .filter((obj) => obj.type === 'image')
        .sort((a, b) => a.id - b.id)
    );

    return (
      oldState.design.designId === newState.design.designId &&
      oldState.design.printColor === newState.design.printColor &&
      oldImageObjects === newImageObjects
    );
  });
  const {currentKonvaObject} = useSelector(
    applicationSelector,
    (newState, oldState) => {
      if (newState.currentKonvaObject?.type == 'image')
        return (
          oldState.currentKonvaObject?.id === newState.currentKonvaObject?.id
        );
      else
        return (
          oldState.currentKonvaObject?.type ===
          newState.currentKonvaObject?.type
        );
    }
  );
  const {canvasHeight, padding, tubeWidth, tubeHeight, inset} = measurements;
  const [objects, setObjects] = useState([]);
  useEffect(() => {
    assembleImageSet();
  }, [currentKonvaObject, design]);

  function assembleImageSet() {
    const objs = design.objects.filter((obj) => {
      return obj.type === 'image';
    });
    if (currentKonvaObject?.type === 'image') {
      const index = objs.findIndex((obj) => {
        return obj.id === currentKonvaObject.id;
      });
      if (index === -1) {
        objs.push(currentKonvaObject);
      } else {
        objs[index] = currentKonvaObject;
      }
    }
    setObjects(objs);
  }

  function handleChange(object, node) {
    const width = object.textWidth ?? object.width;
    const height = object.textHeight ?? object.height;
    const currentObject = {
      ...object,
      x: (node.x() - (inset + padding)) / tubeWidth,
      y: (node.y() - (canvasHeight - tubeHeight) / 2) / tubeHeight,
      height: height,
      width: width,
      scaleX: (node.scaleX() * width) / tubeWidth,
      scaleY: (node.scaleY() * height) / tubeHeight,
      skewX: node.skewX(),
      skewY: node.skewY(),
      offsetX: node.offsetX(),
      offsetY: node.offsetY(),
      rotation: Math.round(node.rotation())
    };
    dispatch(updateCurrentKonvaObject(currentObject));
  }

  function getImageElements() {
    return objects.map((image) => (
      <ImageElement
        key={image.id}
        imageObject={image}
        isSelected={currentKonvaObject && image.id === currentKonvaObject.id}
        measurements={measurements}
        handleChange={handleChange}
      />
    ));
  }

  return <Layer ref={layerRef}>{getImageElements()}</Layer>;
};

export default ImageLayer;
